import type { Saleor, SaleorErrors } from "../en/saleor";

const errors = {
  common: {
    UNIQUE: "Unique",
    INVALID: "Invalide",
    NOT_FOUND: "Non trouvé",
  },
  CheckoutError: {
    VOUCHER_NOT_APPLICABLE: "Bon non applicable",
    REQUIRED: "Requis",
    NO_LINES: "Aucune ligne",
    INVALID: {
      text: "Invalide",
      phone: "Invalide @.lower:address.phoneNumber",
      countryArea: "Invalide @.lower:address.countryArea",
      postalCode: "Invalide @.lower:address.postalCode",
    },
    INVALID_SHIPPING_METHOD: "Méthode d'expédition invalide",
    QUANTITY_GREATER_THAN_LIMIT: "Quantité supérieure à la limite",
    EMAIL_NOT_SET: "Email non défini",
    NOT_FOUND: "Non trouvé",
    PRODUCT_NOT_PUBLISHED: "Produit non publié",
    PRODUCT_UNAVAILABLE_FOR_PURCHASE: "Produit non disponible pour la vente",
    INSUFFICIENT_STOCK: "Stock insuffisant",
    ZERO_QUANTITY: "Quantité à zéro",
    UNAVAILABLE_VARIANT_IN_CHANNEL: "Variante non disponible dans le canal",
  },
  AccountError: {
    INVALID_CREDENTIALS: "Identifiants invalides",
    INVALID_PASSWORD: "Mot de passe invalide",
    NOT_FOUND: "Non trouvé",
    PASSWORD_TOO_SHORT: "Mot de passe trop court",
    PASSWORD_TOO_COMMON: "Mot de passe trop commun",
    PASSWORD_TOO_SIMILAR: "Mot de passe trop similaire",
    REQUIRED: "Requis",
    UNIQUE: "Unique",
    ACCOUNT_NOT_CONFIRMED: "Compte non confirmé",
  },
  TransactionInitializeError: {
    CHECKOUT_COMPLETION_IN_PROGRESS: "Finalisation de la commande en cours",
  },
  TransactionProcessError: {
    NOT_FOUND: "Transaction non trouvée",
    TRANSACTION_ALREADY_PROCESSED: "Transaction déjà traitée",
    INVALID: "Invalide",
    CHECKOUT_COMPLETION_IN_PROGRESS: "Finalisation de la commande en cours",
  },
} satisfies SaleorErrors;

export default {
  errors,
  order: {
    Status: {
      CANCELED: "Annulé",
      DRAFT: "Brouillon",
      EXPIRED: "Expiré",
      FULFILLED: "Réalisé",
      PARTIALLY_FULFILLED: "Partiellement réalisé",
      PARTIALLY_RETURNED: "Partiellement retourné",
      RETURNED: "Retourné",
      UNCONFIRMED: "Non confirmé",
      UNFULFILLED: "Non réalisé",
    },
    ChargeStatus: {
      FULL: "Complet",
      NONE: "Aucun",
      OVERCHARGED: "Surchargé",
      PARTIAL: "Partiel",
    },
  },
  payment: {
    ChargeStatus: {
      CANCELLED: "Annulé",
      FULLY_CHARGED: "Complètement chargé",
      FULLY_REFUNDED: "Complètement remboursé",
      NOT_CHARGED: "Non chargé",
      PARTIALLY_CHARGED: "Partiellement chargé",
      PARTIALLY_REFUNDED: "Partiellement remboursé",
      PENDING: "En attente",
      REFUSED: "Refusé",
    },
  },
} satisfies Saleor;
