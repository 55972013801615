import type { ZodI18n } from "../en/zod";

export default {
  errors: {
    too_small: {
      string: {
        inclusive: "La chaîne doit contenir au moins {minimum} caractère(s)",
      },
    },
    invalid_type_received_undefined: "Une valeur était attendue, mais undefined a été reçu",
    required_field: "Ce champ est obligatoire",
    invalid_type: {
      string: {
        expected: "Une chaîne de caractères était attendue, mais {received} a été reçu",
      },
      number: {
        expected: "Un nombre était attendu, mais {received} a été reçu",
      },
    },
    too_big: {
      string: {
        inclusive: "La chaîne doit contenir au maximum {maximum} caractère(s)",
      },
    },
    invalid_string: {
      email: "Adresse e-mail invalide",
      url: "URL invalide",
    },
    custom: "Entrée invalide",
  },
  validations: {
    email: "Veuillez entrer une adresse e-mail valide",
    phone: "Veuillez entrer un numéro de téléphone valide",
  },
} satisfies ZodI18n;
