import type { SupportBase } from "../en/support";

export default {
  title: "Unterstützung",
  subtitle:
    "Erhalten Sie konsistente, reproduzierbare Daten mit unserer vollständigen Proteinpalette.",
  description:
    "Bei Genotic sind wir bestrebt, Ihnen die Unterstützung und Ressourcen zur Verfügung zu stellen, die Sie benötigen, um das Beste aus unseren Produkten herauszuholen. Unser Support-Team ist da, um sicherzustellen, dass Sie Zugang zu den Werkzeugen, Informationen und Anleitungen haben, die für erfolgreiche Ergebnisse erforderlich sind.",
  sidebar: {
    technicalSupport: "Technischer Support",
    productDocumentation: "Produktdokumentation",
    orderingAndPaymentSupport: "Bestell- und Zahlungsunterstützung",
    shippingAndDelivery: "Versand und Lieferung",
    returnsAndRefunds: "Rückgaben und Erstattungen",
    collaborationsAndCustomOrders: "Kooperationen und Sonderanfertigungen",
    contactInformation: "Kontaktinformationen",
    FAQ: "Häufig gestellte Fragen",
  },
  technicalSupport: {
    title: "Technischer Support",
    description:
      "Wir bieten umfassenden technischen Support für all unsere Produkte. Unser sachkundiges Team steht bereit, um Ihnen bei allen Fragen oder Problemen zu helfen. Für Hilfe zu Produktdetails konsultieren Sie bitte unsere Wissensdatenbank oder wenden Sie sich direkt an unser technisches Support-Team.",
    contact: {
      0: {
        title: "Technischen Support kontaktieren",
        email: "technical_support@genotic.com",
      },
    },
  },
  productDocumentation: {
    title: "Produktdokumentation (Noch zu bestätigen)",
    description:
      "Wir bieten umfassenden technischen Support für all unsere Produkte. Unser sachkundiges Team steht bereit, um Ihnen bei allen Fragen oder Problemen zu helfen. Für Hilfe zu Produktdetails konsultieren Sie bitte unsere Wissensdatenbank oder wenden Sie sich direkt an unser technisches Support-Team.",
    contact: {
      0: {
        title: "Technischen Support kontaktieren",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Bestell- und Zahlungsunterstützung",
    description:
      "Wir bemühen uns, den Bestellvorgang reibungslos und unkompliziert zu gestalten. Ob Sie einen einzelnen Artikel kaufen oder eine Großbestellung aufgeben, wir bieten verschiedene Optionen, die Ihren Bedürfnissen entsprechen.",
    contact: {
      0: {
        title: "Technischen Support kontaktieren",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Versand und Lieferung",
    description:
      "Unsere Produkte werden weltweit mit Fokus auf schnelle, zuverlässige Lieferung verschickt. Wir arbeiten mit vertrauenswürdigen Versanddienstleistern zusammen, um sicherzustellen, dass Ihre Bestellung in optimalem Zustand ankommt.",
    tableItems: {
      1: {
        key: "Versandoptionen",
        value: "Noch zu bestätigen",
      },
      2: {
        key: "Bestellverfolgung",
        value: "Verfolgen Sie Ihre Sendung in Echtzeit, sobald sie versandt wurde.",
      },
      3: {
        key: "Internationaler Versand",
        value: "Noch zu bestätigen",
      },
    },
    contact: {
      0: {
        title: "Technischen Support kontaktieren",
        email: "technical_support@genotic.com",
      },
    },
  },
  returnsAndRefunds: {
    title: "Rückgaben und Erstattungen",
    description:
      "Wir bemühen uns, hochwertige Produkte anzubieten, aber wenn Sie einen Artikel zurückgeben oder eine Rückerstattung beantragen müssen, sind wir für Sie da",
    contact: {
      0: {
        title: "Kontaktieren Sie uns",
        email: "returns_refunds@genotic.com",
      },
    },
  },
  collaborationsAndCustomOrders: {
    title: "Kooperationen und Sonderanfertigungen",
    description:
      "Genotic sucht aktiv die Zusammenarbeit mit Forschungseinrichtungen, Biotechnologieunternehmen und Industriepartnern. Wir bieten flexible Lösungen für diejenigen, die maßgeschneiderte Produkte oder Dienstleistungen benötigen, um ihre spezifischen Forschungsbedürfnisse zu erfüllen.",
    tableItems: {
      1: {
        key: "Kollaborative Forschungsprojekte",
        value:
          "Wir sind bestrebt, Möglichkeiten zur gemeinsamen Entwicklung mit anderen Innovatoren im Bereich der Biowissenschaften zu erkunden. Kontaktieren Sie uns, um potenzielle Partnerschaften zu besprechen.",
      },
      2: {
        key: "Anfragen für Sonderanfertigungen",
        value:
          "Wir bieten maßgeschneiderte Lösungen, einschließlich kundenspezifischer Proteine und Kits, um den einzigartigen Anforderungen Ihrer Forschung gerecht zu werden. Wenden Sie sich an unser Team für weitere Informationen.",
      },
    },
  },
  contactInformation: {
    title: "Kontaktinformationen",
    description:
      "Kontaktieren Sie uns - Wir sind immer für Sie da, ob Sie Hilfe bei einer Bestellung benötigen oder technische Fragen haben. Unser Support-Team steht bereit, um Ihnen zu helfen.",
    contact: {
      0: {
        title: "Allgemeine Anfragen",
        email: "general_inquiries@genotic.com",
      },
      1: {
        title: "Technischer Support",
        email: "technical_support@genotic.com",
      },
    },
  },
  faq: {
    title: "Häufig gestellte Fragen",
    technical: {
      title: "Technisch",
      qAndA: {
        1: {
          question: "Wie sind die empfohlenen Lagerungsbedingungen für Ihre Produkte?",
          answer:
            "Alle lyophilisierten Produkte sollten bei Raumtemperatur gelagert werden. Für Produkte in flüssiger Form wird eine Lagerung bei 4 °C oder im Gefrierschrank empfohlen. Bitte beachten Sie das detaillierte Produktdatenblatt für spezifische Lagerungsanweisungen für jedes Produkt.",
        },
        2: {
          question:
            "Wie erhalte ich Analysezertifikate (COA) oder Sicherheitsdatenblätter (SDS) für Ihre Produkte?",
          answer:
            "Alle notwendigen Dokumente, einschließlich des Sicherheitsdatenblatts, Datenblatts und Analysezertifikats, sind direkt auf der Seite jedes Produkts verfügbar. Diese Dokumente können für Ihre Bequemlichkeit eingesehen und heruntergeladen werden.",
        },
        3: {
          question:
            "Welche Qualitätskontrollmaßnahmen gibt es zur Sicherstellung der Produktkonsistenz?",
          answer:
            "Wir implementieren strenge Qualitätskontrollmaßnahmen für jede Produktcharge. Dazu gehören Gelelektrophorese zur Beurteilung von Reinheit und Molekulargewicht, Biolayer-Interferometrie (BLI) zur Messung von Proteininteraktionen und Oberflächenplasmonenresonanz (SPR)-Analyse für bestimmte Proteine. Für Enzyme führen wir enzymatische Aktivitätstests durch, die für jedes Enzym spezifisch sind, wie im Sicherheitsdatenblatt des Produkts detailliert beschrieben. Zusätzlich führen wir SDS-PAGE und für einige Proteine Western-Blot-Analysen durch, um höchste Qualität zu gewährleisten.",
        },
        4: {
          question: "Wie gehe ich vor, wenn ein Produkt nicht wie erwartet funktioniert?",
          answer:
            "Bitte kontaktieren Sie unser technisches Support-Team für Unterstützung. Wir sind hier, um Ihnen bei der Lösung aller Probleme zu helfen und die optimale Leistung unserer Produkte sicherzustellen.",
        },
        5: {
          question:
            "Bieten Sie kundenspezifische Formulierungen oder Modifikationen Ihrer Produkte an?",
          answer:
            'Ja, wir bieten kundenspezifische Formulierungen und Modifikationen unserer Produkte an. Alle relevanten Informationen finden Sie im Abschnitt "Dienstleistungen" auf unserer Website. Für zusätzliche Informationen kontaktieren Sie uns bitte per E-Mail unter sales@genotic.com.',
        },
        6: {
          question:
            "Sind Ihre Produkte für spezifische Forschungsanwendungen validiert (z.B. Immunoassays, Diagnostik)?",
          answer:
            "Wir sind derzeit dabei, unsere Produkte für spezifische Anwendungen zu validieren. In naher Zukunft werden wir detaillierte Informationen bereitstellen, die angeben, welche Produkte für Anwendungen wie Western Blot und Immunhistochemie geeignet sind. Bitte bleiben Sie für Updates auf dem Laufenden.",
        },
        7: {
          question: "Wie sollte ich mit temperaturempfindlichen Produkten umgehen?",
          answer:
            "Bitte befolgen Sie die spezifischen Lagerungsanweisungen, die im Produktdatenblatt angegeben sind. Im Allgemeinen sollten flüssige Produkte bei 4 °C oder im Gefrierschrank gelagert werden, um ihre Stabilität zu erhalten. Ordnungsgemäße Lagerung gewährleistet die Wirksamkeit und Langlebigkeit des Produkts.",
        },
        8: {
          question:
            "Kann ich auf Protokolle, Anwendungsleitfäden oder technische Dokumentation für Ihre Produkte zugreifen?",
          answer:
            "Derzeit bieten wir keine Protokolle oder Benutzerhandbücher an, planen aber, diese in Zukunft hinzuzufügen. Wir stellen jedoch technische Dokumentation und eine Sammlung von Daten zur Verfügung, die aus wissenschaftlichen Arbeiten zusammengestellt wurden und auf den relevanten Produktseiten zu finden sind. Wir sind bestrebt, Ihre Forschungsbedürfnisse zu unterstützen und werden unsere Ressourcen kontinuierlich aktualisieren.",
        },
        9: {
          question: "Wie lange sind Ihre Produkte haltbar und wie wird dies bestimmt?",
          answer:
            "Die Haltbarkeit unserer Produkte hängt von der Produktionsmethode und den Lagerungsbedingungen ab. Für lyophilisierte Produkte beträgt die Haltbarkeit etwa 12 Monate, wenn sie an einem kühlen, dunklen Ort gelagert werden. Für gefrorene Produkte werden spezifische Haltbarkeitsinformationen bereitgestellt. Da unsere Proteine neu entwickelt sind, führen wir laufende Stabilitätsstudien durch, um präzise Haltbarkeitsdaten zu liefern.",
        },
        10: {
          question: "Wie sieht Ihr Prozess für die Chargen-zu-Chargen-Konsistenz aus?",
          answer:
            "Wir gewährleisten eine außergewöhnliche Chargen-zu-Chargen-Konsistenz, indem wir unsere Antikörper durch genetische Modifikation von Zellen produzieren und stabile Zelllinien etablieren, wodurch der Einsatz von Tieren in der Produktion eliminiert wird. Wir verwenden auch chemisch definierte Medien ohne tierische Substrate, was eine hohe Reproduzierbarkeit und konsistente Qualität über alle Chargen hinweg garantiert.",
        },
        11: {
          question:
            "Wie stellen Sie die Reproduzierbarkeit der Ergebnisse mit Ihren Produkten sicher?",
          answer:
            "Durch den Einsatz konsistenter Produktionsmethoden und die Verwendung chemisch definierter Medien garantieren wir eine hohe Reproduzierbarkeit unserer Produkte. Unsere strengen Qualitätskontrollprozesse stellen sicher, dass Sie zuverlässige und konsistente Ergebnisse in Ihrer Forschung erzielen können.",
        },
        12: {
          question:
            "Was soll ich tun, wenn ich Hilfe bei der Integration des Produkts in meinen Workflow benötige?",
          answer:
            "Bitte kontaktieren Sie unser technisches Support-Team für personalisierte Unterstützung. Unsere Experten stehen bereit, um Ihnen bei der Integration unserer Produkte in Ihren Workflow zu helfen und alle Fragen oder Bedenken zu klären, die Sie haben könnten.",
        },
      },
    },
    nonTechnical: {
      title: "Nicht-Technisch",
      qAndA: {
        1: {
          question: "Wie sieht Ihre Rückgabe- und Erstattungspolitik aus?",
          answer:
            "Wir bieten eine 30-tägige Rückgabepolitik für alle unsere Produkte. Wenn Sie nicht vollständig zufrieden sind - sei es, dass das Produkt unbenutzt ist oder nicht wie erwartet funktioniert hat - bieten wir eine vollständige Rückerstattung ohne Fragen. Wir sind von der Qualität unserer Produkte überzeugt und stehen voll und ganz dahinter. Ihre Zufriedenheit hat für uns oberste Priorität.",
        },
        2: {
          question: "Welche Versandoptionen gibt es und wie lange dauert die Lieferung?",
          answer:
            "Wir bieten verschiedene Versandoptionen, um Ihren Bedürfnissen gerecht zu werden. Die Lieferzeiten hängen vom spezifischen Produkt und Ihrem Standort ab. Genaue Lieferschätzungen werden auf der Seite jedes Produkts angegeben, mit Echtzeit-Bestandsaktualisierungen, um eine genaue Verfügbarkeit zu gewährleisten.",
        },
        3: {
          question: "Versenden Sie international und gibt es Einschränkungen?",
          answer:
            "Ja, wir versenden international. Um eine internationale Bestellung aufzugeben, kontaktieren Sie uns bitte für ein personalisiertes Angebot und um mögliche Einschränkungen basierend auf den Importbestimmungen Ihres Landes zu besprechen. Wir sind bestrebt, den globalen Zugang zu unseren Produkten zu erleichtern und dabei alle geltenden Gesetze und Standards einzuhalten.",
        },
        4: {
          question: "Wie kann ich meine Bestellung nach dem Versand verfolgen?",
          answer:
            "Sobald Ihre Bestellung versandt wurde, senden wir Ihnen eine Tracking-Nummer per E-Mail. Sie können diese Nummer verwenden, um den Fortschritt Ihrer Sendung über das Tracking-System des Spediteurs zu verfolgen. Wenn Sie Hilfe bei der Verfolgung Ihrer Bestellung benötigen, zögern Sie bitte nicht, uns zu kontaktieren.",
        },
        5: {
          question: "Welche Zahlungsmethoden akzeptieren Sie?",
          answerHTML:
            '{"time": 1727873139304, "blocks": [{"id": "Jrq99IXsgB", "data": {"text": "Wir akzeptieren die folgenden Zahlungsmethoden:"}, "type": "paragraph"}, {"id": "-0L4jom3aw", "data": {"items": ["Banküberweisung", "Kredit- und Debitkartenzahlungen", "Lokale Zahlungsmethoden, die von Stripe unterstützt werden"], "style": "unordered"}, "type": "list"}], "version": "2.24.3"}',
        },
        6: {
          question:
            "Wie kann ich ein Angebot für Großbestellungen oder Sonderanfertigungen anfordern?",
          answer:
            "Für Großbestellungen kontaktieren Sie uns bitte per E-Mail unter sales@genotic.com ",
        },
        7: {
          question:
            "Bieten Sie Rabatte für akademische Einrichtungen, gemeinnützige Organisationen oder Großeinkäufe an?",
          answer:
            "Ja, wir bieten Rabatte für Großbestellungen, akademische Einrichtungen und gemeinnützige Organisationen an. Wir können Gutscheine bereitstellen, die Rabatte gewähren. Bitte kontaktieren Sie uns, um zu besprechen, wie wir Ihren Bedürfnissen entgegenkommen können.",
        },
        8: {
          question: "Kann ich ein Kreditkonto bei Ihrem Unternehmen eröffnen?",
          answer:
            "Ja, wir bieten Kreditkonten für Unternehmen an, mit denen wir eine etablierte Zusammenarbeit haben. Sobald wir sehen, dass alles gut funktioniert, können wir Zahlungsbedingungen nach der Bestellung vereinbaren. Bitte kontaktieren Sie uns, um den Antragsvorgang zu besprechen.",
        },
        9: {
          question: "Wie sieht Ihre Garantiepolitik für Produkte aus?",
          answer:
            "Wir stehen hinter der Qualität und Zuverlässigkeit unserer Produkte. Jedes Produkt wird mit detaillierten Informationen und Forschungsdokumentationen geliefert, die die spezifischen Tests und Qualitätskontrollen beschreiben. Unsere Produkte sind über Chargen hinweg konsistent, da wir tierfreie Produktionsmethoden verwenden. Wenn Sie auf Probleme stoßen, bieten wir eine vollständige Rückerstattung oder einen Ersatz an. Unsere Garantiepolitik spiegelt unser Engagement wider, zuverlässige, hochwertige Produkte zu liefern, die Ihren Forschungsbedürfnissen entsprechen.",
        },
        10: {
          question:
            "Sind Sie an Nachhaltigkeits- oder ethischen Beschaffungsinitiativen beteiligt?",
          answer:
            "Obwohl wir ein kleines Unternehmen sind und noch nicht offiziell an Nachhaltigkeitsinitiativen teilnehmen, sind wir verantwortungsvollen Praktiken verpflichtet. Wir implementieren erneuerbare Energielösungen mit dem Ziel, netzunabhängig zu arbeiten. Zusätzlich verwenden wir keine Tiere in unserem Antikörper-Design.",
        },
        11: {
          question: "Kann ich eine Produktdemonstration oder Beratung planen?",
          answer:
            "Ja, wir würden uns freuen, eine Produktdemonstration oder Beratung für Sie zu arrangieren. Wir können Videoanrufe planen, um unsere Produkte im Detail zu besprechen und alle Fragen zu beantworten, die Sie haben könnten. Bitte senden Sie uns eine E-Mail an general_inquires@genotic.com, um einen passenden Termin zu vereinbaren.",
        },
        12: {
          question:
            "Wie kann ich meine Kontoinformationen aktualisieren oder meine Bestellungen online verwalten?",
          answer:
            "Sie können Ihre Kontoinformationen aktualisieren und Ihre Bestellungen verwalten, indem Sie sich in Ihr Konto auf unserer Website einloggen. Alles ist über das Benutzerpanel zugänglich, wo Sie persönliche Details bearbeiten, Ihre Bestellhistorie überprüfen und aktuelle Bestellungen verfolgen können.",
        },
        13: {
          question:
            "An wen wende ich mich für allgemeinen Kundensupport oder technische Unterstützung?",
          answer:
            "Für allgemeinen Kundensupport oder technische Unterstützung finden Sie bitte unten die relevanten Kontaktinformationen.",
        },
      },
    },
  },
} satisfies SupportBase;
