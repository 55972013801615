import type { SaleorBase, SaleorErrorsBase } from "../types";

const saleorErrors = {
  common: {
    UNIQUE: "Unique",
    INVALID: "Invalid",
    NOT_FOUND: "Not found",
  },
  CheckoutError: {
    VOUCHER_NOT_APPLICABLE: "Voucher not applicable",
    REQUIRED: "Required",
    NO_LINES: "No lines",
    INVALID: {
      text: "Invalid",
      phone: "Invalid @.lower:address.phoneNumber",
      countryArea: "Invalid @.lower:address.countryArea",
      postalCode: "Invalid @.lower:address.postalCode",
    },
    INVALID_SHIPPING_METHOD: "Invalid shipping method",
    QUANTITY_GREATER_THAN_LIMIT: "Quantity greater than limit",
    EMAIL_NOT_SET: "Email not set",
    NOT_FOUND: "Not found",
    PRODUCT_NOT_PUBLISHED: "Product not published",
    PRODUCT_UNAVAILABLE_FOR_PURCHASE: "Product unavailable for purchase",
    INSUFFICIENT_STOCK: "Insufficient stock",
    ZERO_QUANTITY: "Zero quantity",
    UNAVAILABLE_VARIANT_IN_CHANNEL: "Unavailable variant in channel",
  },
  AccountError: {
    INVALID_CREDENTIALS: "Invalid credentials",
    INVALID_PASSWORD: "Invalid password",
    NOT_FOUND: "Not found",
    PASSWORD_TOO_SHORT: "Password too short",
    PASSWORD_TOO_COMMON: "Password too common",
    PASSWORD_TOO_SIMILAR: "Password too similar",
    REQUIRED: "Required",
    UNIQUE: "Unique",
    ACCOUNT_NOT_CONFIRMED: "Account not confirmed",
  },
  TransactionInitializeError: {
    CHECKOUT_COMPLETION_IN_PROGRESS: "Checkout completion in progress",
  },
  TransactionProcessError: {
    NOT_FOUND: "Transaction not found",
    TRANSACTION_ALREADY_PROCESSED: "Transaction already processed",
    INVALID: "Invalid",
    CHECKOUT_COMPLETION_IN_PROGRESS: "Checkout completion in progress",
  },
} satisfies SaleorErrorsBase;

const saleor = {
  errors: saleorErrors,
  order: {
    Status: {
      CANCELED: "Canceled",
      DRAFT: "Draft",
      EXPIRED: "Expired",
      FULFILLED: "Fulfilled",
      PARTIALLY_FULFILLED: "Partially fulfilled",
      PARTIALLY_RETURNED: "Partially returned",
      RETURNED: "Returned",
      UNCONFIRMED: "Unconfirmed",
      UNFULFILLED: "Unfulfilled",
    },
    ChargeStatus: {
      FULL: "Full",
      NONE: "None",
      OVERCHARGED: "Overcharged",
      PARTIAL: "Partial",
    },
  },
  payment: {
    ChargeStatus: {
      CANCELLED: "Cancelled",
      FULLY_CHARGED: "Fully charged",
      FULLY_REFUNDED: "Fully refunded",
      NOT_CHARGED: "Not charged",
      PARTIALLY_CHARGED: "Partially charged",
      PARTIALLY_REFUNDED: "Partially refunded",
      PENDING: "Pending",
      REFUSED: "Refused",
    },
  },
} satisfies SaleorBase;

export type SaleorErrors = typeof saleorErrors;
export type Saleor = typeof saleor;

export default saleor;
