import type { I18nLocale } from "../en";
import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

export default {
  support,
  saleor,
  error,
  zodI18n,
  countries,
  title: "Genotic",
  common: {
    cancel: "Annuler",
    text: "Texte",
    edit: "Modifier",
    save: "Enregistrer",
    add: "Ajouter",
    showMore: "Afficher plus",
    showLess: "Afficher moins",
    day: "Jour | Jours",
    show: "Afficher",
    close: "Fermer",
    hide: "Masquer",
    download: "Télécharger | Téléchargements",
    search: "Rechercher",
    scrollToTop: "Défiler vers le haut",
    delete: "Supprimer",
    or: "ou",
    submit: "Valider",
    submitting: "Validation en cours",
    open: "Ouvrir",
  },
  unit: {
    pc: "pce",
  },
  footer: {
    termsAndConditions: "Termes et conditions",
    privacyPolicy: "Politique de confidentialité",
    new: "Nouveau",
    hiring: "Nous recrutons !",
  },
  header: {
    all: "Tous",
    myAccount: "Mon compte",
    cart: "Panier",
    signOut: "Se déconnecter",
    languageDisplay: "Français (EUR)",
    countryWithCurrency: "France (EUR)",
    menu: "Menu",
    search: "Rechercher",
  },
  search: {
    closeSearchResults: "Fermer les résultats de recherche",
    searchResultsInIndividualCategories: "Résultats de recherche dans les catégories individuelles",
    resultsInIndividualCategories: "Résultats dans les catégories individuelles",
  },
  profile: {
    title: "Profil",
    info: "Infos",
    addresses: "Adresses",
    giftCards: "Cartes cadeaux",
    orders: {
      title: "Commandes",
      empty: "Vous n'avez pas créé de commandes",
    },
    editProfile: "Editer le profil",
  },
  address: {
    title: "Adresse",
    shippingAddress: "Adresse de livraison | Adresses de livraison",
    billingAddress: "Adresse de facturation | Adresses de facturation",
    defaultAddress: "Adresse par défaut",
    thisAddressIsUsedForBothShippingAndBilling:
      "Cette adresse est utilisée pour les livraisons et les facturations",
    defaultForShippingAndBilling: "Par défaut pour les livraisons et les facturations",
    defaultShippingAddress: "Adresse de livraison par défaut",
    defaultBillingAddress: "Adresse de facturation par défaut",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Adresse ligne 1",
    streetAddress2: "Adresse ligne 2",
    postalCode: "Code postal",
    city: "Ville",
    cityArea: "Zone de la ville",
    email: "@:auth.email",
    country: "Pays",
    countryArea: "État / Province",
    vat: "VAT",
    selectACountry: "Sélectionnez un pays",
    companyName: "Nom de l'entreprise/institution",
    phoneNumber: "Numéro de téléphone",
    billingMatchesShippingAddress: "L'adresse de facturation correspond à l'adresse de livraison",
    editAddress: "Modifier l'adresse",
    deleteAddress: "Supprimer l'adresse",
    addAddress: "Ajouter une adresse",
    noAddress: "Aucune adresse",
    empty: "Vous n'avez pas d'adresse",
    noDefaultAddress: "Aucune adresse par défaut",
    organizationInfo: "Información de la organización",
  },
  order: {
    cart: {
      empty: "Votre panier est vide",
      title: "Panier",
      adjustItemQuantity: "Ajuster la quantité d'article",
      shippingFrom: "Expédition à partir de {amount}",
    },
    checkout: {
      title: "Commande",
      addAnythingToCartToContinue: "Ajoutez quelque chose à votre panier pour continuer",
      goBackToStore: "Retour à la boutique",
      delivery: "Livraison",
      completeCheckout: "Terminer la commande",
      haveAPromoCode: "J'ai un code promo",
      promoCode: "Code promo",
      specifyHowYouWantToPlaceYourOrder: "Indiquez comment vous souhaitez placer votre commande",
      continueAsGuest: "Continuer en tant qu'invité",
      continueAsGuestDescription:
        "Dans chaque moment du processus de commande, vous pouvez créer un profil membre gratuit",
      iHaveAnAccount: "J'ai un compte",
      createAccount: "Créer un compte",
      continueAsMember: "Continuer en tant que membre",
    },
    steps: {
      address: "Adresse",
      shipping: "Livraison",
      payment: "Paiement",
      continue: "Continuer",
      back: "Retour",
    },
    paymentMethods: {
      title: "Moyens de paiement",
      bankTransfer: "Transfert bancaire",
    },
    number: "Numéro de commande",
    status: "Statut",
    paid: "Payée",
    unpaid: "Impayée",
    paymentStatus: "Statut de paiement",
    complete: "Commande terminée",
    quantity: "Quantité",
    info: "Informations sur la commande",
    arrives: "arrive",
    addToCart: "Ajouter au panier",
    addedToCart: "Le produit {product} a été ajouté à votre panier",
    goToCart: "Aller au panier",
    goToCheckout: "Passer à la caisse",
    shippingTo: "Expédition vers",
    total: "Total",
    subtotal: "Sous-total",
    shipping: {
      title: "livraison",
      country: "France",
      noShippingMethodsAvailable: "Aucune méthode de livraison disponible",
      shippingMethods: "Méthodes d'expédition",
    },
    details: {
      title: "Détails de la commande",
      qty: "Qté",
    },
  },
  auth: {
    login: "Connexion",
    register: "Inscription",
    logout: "Déconnexion",
    email: "E-mail",
    password: "Mot de passe",
    password2: "Confirmer le mot de passe",
    firstName: "Prénom",
    lastName: "Nom",
    alreadyHaveAnAccount: "Vous avez déjà un compte ?",
    dontHaveAnAccount: "Vous n'avez pas de compte ?",
    forgotPassword: "Mot de passe oublié",
    createNewAccount: "Créer un compte",
  },
  product: {
    title: "Produit | Produits",
    addToCart: "Ajouter au panier",
    addedToCart: {
      toastTitle: "Le produit {product} a été ajouté à votre panier",
      toastDescription: "Vous pouvez maintenant le voir là...",
    },
    outOfStock: "Rupture de stock",
    synonyms: "Synonymes",
    noProductsFound: "Aucun produit trouvé",
    description: {
      recommendedDilution: "Dilution recommandée",
      applications: "Application | Applications",
      dilution: "Dilution",
      testedApplications: "Applications testées",
      targetSpecies: "Espèces cibles",
      targetAliases: "Alias cibles",
      species: "Espèces",
      uniProtIdAndGenId: "ID UniProt et ID Génétique",
      images: "Image | Images",
      publications: "Publication | Publications",
      authors: "Auteur | Auteurs",
      performanceData: "Données de performance",
      support: "Support",
      productSpecificProtocols: "Protocoles spécifiques au produit",
      frequentlyAskedQuestions: "Questions fréquentes",
      contact: "Contact",
      productDetails: "Détails du produit",
      targetInfo: "Informations sur la cible",
      relatedProducts: "Produit lié | Produits liés",
      files: "Fichiers",
    },
  },
  ui: {
    availability: {
      available: "Disponible",
      unavailable: "Indisponible",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Catégorie | Catégories",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Catégories",
    noCategoriesFound: "Aucune catégorie trouvée",
  },
  warning: {
    thisActionCannotBeUndone: "Cette action ne peut pas être annulée",
  },
} satisfies I18nLocale;
