import type { SupportBase } from "../en/support";

export default {
  title: "Wsparcie",
  subtitle: "Uzyskaj spójne, powtarzalne dane dzięki naszej pełnej gamie białek.",
  description:
    "W Genotic jesteśmy zobowiązani do zapewnienia wsparcia i zasobów potrzebnych do jak najlepszego wykorzystania naszych produktów. Nasz zespół wsparcia jest tutaj, aby zapewnić dostęp do narzędzi, informacji i wskazówek niezbędnych do osiągnięcia pomyślnych wyników.",
  sidebar: {
    technicalSupport: "Wsparcie techniczne",
    productDocumentation: "Dokumentacja produktu",
    orderingAndPaymentSupport: "Wsparcie w zakresie zamawiania i płatności",
    shippingAndDelivery: "Wysyłka i dostawa",
    returnsAndRefunds: "Zwroty i refundacje",
    collaborationsAndCustomOrders: "Współpraca i zamówienia niestandardowe",
    contactInformation: "Informacje kontaktowe",
    FAQ: "Często zadawane pytania",
  },
  technicalSupport: {
    title: "Wsparcie techniczne",
    description:
      "Oferujemy kompleksowe wsparcie techniczne dla wszystkich naszych produktów. Nasz wykwalifikowany zespół jest gotowy pomóc w przypadku wszelkich pytań lub problemów. Aby uzyskać pomoc dotyczącą szczegółów produktu, prosimy zapoznać się z naszą Bazą Wiedzy lub skontaktować się bezpośrednio z naszym zespołem wsparcia technicznego.",
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  productDocumentation: {
    title: "Dokumentacja produktu (Do potwierdzenia)",
    description:
      "Oferujemy kompleksowe wsparcie techniczne dla wszystkich naszych produktów. Nasz wykwalifikowany zespół jest gotowy pomóc w przypadku wszelkich pytań lub problemów. Aby uzyskać pomoc dotyczącą szczegółów produktu, prosimy zapoznać się z naszą Bazą Wiedzy lub skontaktować się bezpośrednio z naszym zespołem wsparcia technicznego.",
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Wsparcie w zakresie zamawiania i płatności",
    description:
      "Staramy się, aby proces zamawiania był płynny i prosty. Niezależnie od tego, czy kupujesz pojedynczy przedmiot, czy składasz duże zamówienie, oferujemy różne opcje dostosowane do Twoich potrzeb.",
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Wysyłka i dostawa",
    description:
      "Nasze produkty są wysyłane na całym świecie, z naciskiem na szybką i niezawodną dostawę. Współpracujemy z zaufanymi dostawcami usług wysyłkowych, aby zapewnić, że Twoje zamówienie dotrze w optymalnym stanie.",
    tableItems: {
      1: {
        key: "Opcje wysyłki",
        value: "Do potwierdzenia",
      },
      2: {
        key: "Śledzenie zamówienia",
        value: "Śledź swoją przesyłkę w czasie rzeczywistym po jej wysłaniu.",
      },
      3: {
        key: "Wysyłka międzynarodowa",
        value: "Do potwierdzenia",
      },
    },
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  returnsAndRefunds: {
    title: "Zwroty i refundacje",
    description:
      "Staramy się dostarczać produkty wysokiej jakości, ale jeśli potrzebujesz zwrócić przedmiot lub poprosić o zwrot pieniędzy, jesteśmy tutaj, aby pomóc",
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  collaborationsAndCustomOrders: {
    title: "Współpraca i zamówienia niestandardowe",
    description:
      "Genotic aktywnie poszukuje współpracy z instytucjami badawczymi, firmami biotechnologicznymi i partnerami branżowymi. Oferujemy elastyczne rozwiązania dla tych, którzy potrzebują dostosowanych produktów lub usług do swoich specyficznych potrzeb badawczych.",
    tableItems: {
      1: {
        key: "Projekty badawcze we współpracy",
        value:
          "Chętnie badamy możliwości wspólnego rozwoju z innymi innowatorami w dziedzinie nauk przyrodniczych. Skontaktuj się z nami, aby omówić potencjalne partnerstwa.",
      },
      2: {
        key: "Zamówienia na produkty niestandardowe",
        value:
          "Oferujemy dostosowane rozwiązania, w tym niestandardowe białka i zestawy, aby sprostać unikalnym wymaganiom Twoich badań. Skontaktuj się z naszym zespołem, aby uzyskać więcej informacji.",
      },
    },
  },
  contactInformation: {
    title: "Informacje kontaktowe",
    description:
      "Skontaktuj się z nami - Zawsze jesteśmy tutaj, aby pomóc, niezależnie od tego, czy potrzebujesz pomocy przy zamówieniu, czy masz pytania techniczne. Nasz zespół wsparcia jest gotowy, aby Ci pomóc.",
    contact: {
      0: {
        title: "Ogólne zapytania",
        email: "general_inquiries@genotic.com",
      },
      1: {
        title: "Wsparcie techniczne",
        email: "technical_support@genotic.com",
      },
    },
  },
  faq: {
    title: "Często Zadawane Pytania",
    technical: {
      title: "Techniczne",
      qAndA: {
        1: {
          question: "Jakie są zalecane warunki przechowywania dla waszych produktów?",
          answer:
            "Wszystkie produkty liofilizowane powinny być przechowywane w temperaturze pokojowej. Dla produktów w formie płynnej zaleca się przechowywanie w temperaturze 4°C lub w zamrażarce. Prosimy zapoznać się ze szczegółową kartą produktu, aby uzyskać konkretne instrukcje przechowywania dla każdego produktu.",
        },
        2: {
          question:
            "Jak mogę uzyskać Certyfikaty Analizy (COA) lub Karty Charakterystyki (SDS) dla waszych produktów?",
          answer:
            "Wszystkie niezbędne dokumenty, w tym Karta Charakterystyki, Karta Danych i Certyfikat Analizy, są bezpośrednio dostępne na stronie każdego produktu. Dokumenty te można przeglądać i pobierać dla wygody użytkownika.",
        },
        3: {
          question:
            "Jakie środki kontroli jakości są stosowane w celu zapewnienia spójności produktów?",
          answer:
            "Wdrażamy rygorystyczne środki kontroli jakości dla każdej partii produktów. Obejmuje to elektroforezę żelową do oceny czystości i masy cząsteczkowej, Interferometrię Biolayer (BLI) do pomiaru interakcji białek oraz analizę Rezonansu Plazmonów Powierzchniowych (SPR) dla niektórych białek. Dla enzymów przeprowadzamy testy aktywności enzymatycznej specyficzne dla każdego enzymu, jak szczegółowo opisano w karcie charakterystyki produktu. Dodatkowo przeprowadzamy analizy SDS-PAGE, a dla niektórych białek analizy Western Blot, aby zapewnić najwyższą jakość.",
        },
        4: {
          question: "Jak rozwiązać problem, jeśli produkt nie działa zgodnie z oczekiwaniami?",
          answer:
            "Prosimy o kontakt z naszym zespołem wsparcia technicznego w celu uzyskania pomocy. Jesteśmy tutaj, aby pomóc rozwiązać wszelkie problemy i zapewnić optymalne działanie naszych produktów.",
        },
        5: {
          question: "Czy oferujecie niestandardowe formulacje lub modyfikacje waszych produktów?",
          answer:
            'Tak, oferujemy niestandardowe formulacje i modyfikacje naszych produktów. Wszystkie istotne informacje można znaleźć w sekcji "Usługi" na naszej stronie internetowej. Aby uzyskać dodatkowe informacje, prosimy o kontakt mailowy na adres sales@genotic.com.',
        },
        6: {
          question:
            "Czy wasze produkty są zwalidowane do konkretnych zastosowań badawczych (np. immunotesty, diagnostyka)?",
          answer:
            "Obecnie jesteśmy w trakcie walidacji naszych produktów do konkretnych zastosowań. W najbliższej przyszłości dostarczymy szczegółowe informacje wskazujące, które produkty są odpowiednie do zastosowań takich jak Western Blot i immunohistochemia. Prosimy o śledzenie aktualizacji.",
        },
        7: {
          question: "Jak powinienem obchodzić się z produktami wrażliwymi na temperaturę?",
          answer:
            "Prosimy przestrzegać konkretnych instrukcji przechowywania podanych w karcie produktu. Ogólnie rzecz biorąc, produkty płynne powinny być przechowywane w temperaturze 4°C lub w zamrażarce, aby zachować ich stabilność. Właściwe przechowywanie zapewnia skuteczność i trwałość produktu.",
        },
        8: {
          question:
            "Czy mogę uzyskać dostęp do protokołów, przewodników użytkowania lub dokumentacji technicznej dla waszych produktów?",
          answer:
            "W tej chwili nie oferujemy protokołów ani przewodników użytkownika, ale planujemy je dodać w przyszłości. Niemniej jednak, dostarczamy dokumentację techniczną i zbiór danych zagregowanych z artykułów naukowych, które można znaleźć na odpowiednich stronach produktów. Jesteśmy zaangażowani we wspieranie Twoich potrzeb badawczych i będziemy stale aktualizować nasze zasoby.",
        },
        9: {
          question: "Jaki jest okres trwałości waszych produktów i jak jest on określany?",
          answer:
            "Okres trwałości naszych produktów zależy od metody produkcji i warunków przechowywania. Dla produktów liofilizowanych okres trwałości wynosi około 12 miesięcy, gdy są przechowywane w chłodnym, ciemnym miejscu. Dla produktów mrożonych zostaną dostarczone konkretne informacje o okresie trwałości. Ponieważ nasze białka są nowo opracowane, prowadzimy bieżące badania stabilności, aby dostarczyć precyzyjne dane dotyczące okresu trwałości.",
        },
        10: {
          question: "Jaki jest wasz proces zapewniania spójności między partiami?",
          answer:
            "Zapewniamy wyjątkową spójność między partiami poprzez produkcję naszych przeciwciał za pomocą modyfikacji genetycznej komórek i ustanowienie stabilnych linii komórkowych, eliminując wykorzystanie zwierząt w produkcji. Wykorzystujemy również chemicznie zdefiniowane media bez żadnych substratów pochodzenia zwierzęcego, gwarantując wysoką powtarzalność i spójną jakość we wszystkich partiach.",
        },
        11: {
          question: "Jak zapewniacie powtarzalność wyników przy użyciu waszych produktów?",
          answer:
            "Stosując spójne metody produkcji i używając chemicznie zdefiniowanych mediów, gwarantujemy wysoką powtarzalność naszych produktów. Nasze rygorystyczne procesy kontroli jakości zapewniają, że możesz osiągnąć wiarygodne i spójne wyniki w swoich badaniach.",
        },
        12: {
          question:
            "Co powinienem zrobić, jeśli potrzebuję pomocy w integracji produktu z moim przepływem pracy?",
          answer:
            "Prosimy o kontakt z naszym zespołem wsparcia technicznego w celu uzyskania spersonalizowanej pomocy. Nasi eksperci są gotowi pomóc Ci zintegrować nasze produkty z Twoim przepływem pracy i odpowiedzieć na wszelkie pytania lub wątpliwości, jakie możesz mieć.",
        },
      },
    },
    nonTechnical: {
      title: "Nietechniczne",
      qAndA: {
        1: {
          question: "Jaka jest wasza polityka zwrotów i refundacji?",
          answer:
            "Oferujemy 30-dniową politykę zwrotów na wszystkie nasze produkty. Jeśli nie jesteś w pełni zadowolony - niezależnie od tego, czy produkt jest nieużywany, czy nie działał zgodnie z oczekiwaniami - zapewniamy pełny zwrot pieniędzy bez zadawania żadnych pytań. Jesteśmy pewni jakości naszych produktów i w pełni za nimi stoimy. Twoje zadowolenie jest naszym najwyższym priorytetem.",
        },
        2: {
          question: "Jakie są wasze opcje wysyłki i jak długo trwa dostawa?",
          answer:
            "Oferujemy różne opcje wysyłki, aby sprostać Twoim potrzebom. Czasy dostawy zależą od konkretnego produktu i Twojej lokalizacji. Dokładne szacunki czasu dostawy są podane na stronie każdego produktu, wraz z aktualizacjami stanu magazynowego w czasie rzeczywistym, aby zapewnić dokładną dostępność.",
        },
        3: {
          question: "Czy wysyłacie międzynarodowo i czy są jakieś ograniczenia?",
          answer:
            "Tak, wysyłamy międzynarodowo. Aby złożyć zamówienie międzynarodowe, prosimy o kontakt w celu uzyskania spersonalizowanej wyceny i omówienia potencjalnych ograniczeń wynikających z przepisów importowych Twojego kraju. Jesteśmy zaangażowani w ułatwianie globalnego dostępu do naszych produktów, przestrzegając jednocześnie wszystkich obowiązujących praw i standardów.",
        },
        4: {
          question: "Jak mogę śledzić moje zamówienie po jego wysłaniu?",
          answer:
            "Po wysłaniu zamówienia wyślemy Ci numer śledzenia e-mailem. Możesz użyć tego numeru do monitorowania postępu przesyłki za pomocą systemu śledzenia przewoźnika. Jeśli potrzebujesz pomocy w śledzeniu zamówienia, prosimy o kontakt.",
        },
        5: {
          question: "Jakie metody płatności akceptujecie?",
          answerHTML:
            '{"time": 1727873139304, "blocks": [{"id": "Jrq99IXsgB", "data": {"text": "Akceptujemy następujące metody płatności:"}, "type": "paragraph"}, {"id": "-0L4jom3aw", "data": {"items": ["Przelewy bankowe", "Płatności kartą kredytową i debetową", "Lokalne metody płatności obsługiwane przez Stripe"], "style": "unordered"}, "type": "list"}], "version": "2.24.3"}',
        },
        6: {
          question: "Jak mogę poprosić o wycenę dla zamówień hurtowych lub niestandardowych?",
          answer:
            "W przypadku zamówień hurtowych prosimy o kontakt mailowy na adres sales@genotic.com ",
        },
        7: {
          question:
            "Czy oferujecie zniżki dla instytucji akademickich, organizacji non-profit lub zakupów hurtowych?",
          answer:
            "Tak, oferujemy zniżki na zamówienia hurtowe, dla instytucji akademickich i organizacji non-profit. Możemy dostarczyć kupony, które przyznają zniżki. Prosimy o kontakt, aby omówić, jak możemy dostosować się do Twoich potrzeb.",
        },
        8: {
          question: "Czy mogę otworzyć konto kredytowe w waszej firmie?",
          answer:
            "Tak, oferujemy konta kredytowe firmom, z którymi mamy ustaloną współpracę. Gdy zobaczymy, że wszystko działa dobrze, możemy ustalić warunki płatności po zamówieniu. Prosimy o kontakt, aby omówić proces aplikacji.",
        },
        9: {
          question: "Jaka jest wasza polityka gwarancyjna dla produktów?",
          answer:
            "Stoimy za jakością i niezawodnością naszych produktów. Każdy produkt jest dostarczany ze szczegółowymi informacjami i dokumentacją badawczą opisującą konkretne testy i kontrole jakości, które zostały przeprowadzone. Nasze produkty są spójne między partiami, ponieważ wykorzystujemy metody produkcji bez użycia zwierząt. Jeśli napotkasz jakiekolwiek problemy, oferujemy pełny zwrot pieniędzy lub wymianę. Nasza polityka gwarancyjna odzwierciedla nasze zaangażowanie w dostarczanie niezawodnych, wysokiej jakości produktów, które spełniają Twoje potrzeby badawcze.",
        },
        10: {
          question:
            "Czy jesteście zaangażowani w jakiekolwiek inicjatywy zrównoważonego rozwoju lub etycznego zaopatrzenia?",
          answer:
            "Chociaż jesteśmy małą firmą i jeszcze oficjalnie nie uczestniczymy w inicjatywach zrównoważonego rozwoju, jesteśmy zaangażowani w odpowiedzialne praktyki. Wdrażamy rozwiązania z zakresu energii odnawialnej, dążąc do działania poza siecią. Dodatkowo nie używamy zwierząt w projektowaniu naszych przeciwciał.",
        },
        11: {
          question: "Czy mogę zaplanować demonstrację produktu lub konsultację?",
          answer:
            "Tak, z przyjemnością zorganizujemy dla Ciebie demonstrację produktu lub konsultację. Możemy zaplanować rozmowy wideo, aby szczegółowo omówić nasze produkty i odpowiedzieć na wszelkie pytania, jakie możesz mieć. Prosimy o kontakt mailowy na adres general_inquires@genotic.com, aby ustalić dogodny termin.",
        },
        12: {
          question:
            "Jak mogę zaktualizować informacje o moim koncie lub zarządzać moimi zamówieniami online?",
          answer:
            "Możesz zaktualizować informacje o swoim koncie i zarządzać zamówieniami, logując się na swoje konto na naszej stronie internetowej. Wszystko jest dostępne przez panel użytkownika, gdzie możesz edytować dane osobowe, przeglądać historię zamówień i śledzić bieżące zamówienia.",
        },
        13: {
          question:
            "Z kim mam się skontaktować w sprawie ogólnego wsparcia klienta lub pomocy technicznej?",
          answer:
            "W sprawie ogólnego wsparcia klienta lub pomocy technicznej, prosimy spojrzeć poniżej na odpowiednie informacje kontaktowe.",
        },
      },
    },
  },
} satisfies SupportBase;
