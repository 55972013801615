import type { ZodI18n } from "../en/zod";

export default {
  errors: {
    too_small: {
      string: {
        inclusive: "Pole musi zawierać co najmniej {minimum} znak(ów)",
      },
    },
    invalid_type_received_undefined: "Oczekiwano wartości, ale otrzymano undefined",
    required_field: "To pole jest wymagane",
    invalid_type: {
      string: {
        expected: "Oczekiwano ciągu znaków, ale otrzymano {received}",
      },
      number: {
        expected: "Oczekiwano liczby, ale otrzymano {received}",
      },
    },
    too_big: {
      string: {
        inclusive: "Ciąg może zawierać maksymalnie {maximum} znak(ów)",
      },
    },
    invalid_string: {
      email: "Nieprawidłowy adres e-mail",
      url: "Nieprawidłowy adres URL",
    },
    custom: "Nieprawidłowe dane wejściowe",
  },
  validations: {
    email: "Podaj prawidłowy adres e-mail",
    phone: "Podaj prawidłowy numer telefonu",
  },
} satisfies ZodI18n;
