const support = {
  title: "Support",
  subtitle: "Get consistent, reproducible data with our full range of proteins.",
  description:
    "At Genotic, we are committed to providing the support and resources you need to make the most of our products. Our support team is here to ensure you have access to the tools, information, and guidance required to achieve successful results.",
  sidebar: {
    technicalSupport: "Technical Support",
    productDocumentation: "Product Documentation",
    orderingAndPaymentSupport: "Ordering and Payment Support",
    shippingAndDelivery: "Shipping and Delivery",
    returnsAndRefunds: "Returns and Refunds",
    collaborationsAndCustomOrders: "Collaborations and Custom Orders",
    contactInformation: "Contact Information",
    FAQ: "FAQ",
  },
  technicalSupport: {
    title: "Technical Support",
    description:
      "We offer comprehensive technical support for all our products. Our knowledgeable team is ready to assist with any questions or troubleshooting you may have. For help with product details, please refer to our Knowledge Base or reach out directly to our technical support team.",
    contact: {
      0: {
        title: "Contact Technical Support",
        email: "technical_support@genotic.com",
      },
    },
  },
  productDocumentation: {
    title: "Product Documentation (TBC)",
    description:
      "We offer comprehensive technical support for all our products. Our knowledgeable team is ready to assist with any questions or troubleshooting you may have. For help with product details, please refer to our Knowledge Base or reach out directly to our technical support team.",
    contact: {
      0: {
        title: "Contact Technical Support",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Ordering and Payment Support",
    description:
      "We aim to make the ordering process smooth and straightforward. Whether you’re purchasing a single item or placing a bulk order, we offer various options to suit your needs.",
    contact: {
      0: {
        title: "Contact Technical Support",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Shipping and Delivery",
    description:
      "Our products are shipped worldwide with a focus on fast, reliable delivery. We partner with trusted shipping providers to ensure your order arrives in optimal condition.",
    tableItems: {
      1: {
        key: "Shipping Options",
        value: "TBC",
      },
      2: {
        key: "Order Tracking",
        value: "Track your shipment in real-time once it’s dispatched.",
      },
      3: {
        key: "International Shipping",
        value: "TBC",
      },
    },
    contact: {
      0: {
        title: "Contact Technical Support",
        email: "technical_support@genotic.com",
      },
    },
  },
  returnsAndRefunds: {
    title: "Returns and Refunds",
    description:
      "We strive to provide high-quality products, but if you need to return an item or request a refund, we’re here to help",
    contact: {
      0: {
        title: "Contact Us",
        email: "returns_refunds@genotic.com",
      },
    },
  },
  collaborationsAndCustomOrders: {
    title: "Collaborations and Custom Orders",
    description:
      "Genotic actively seeks collaboration with research institutions, biotech firms, and industry partners. We offer flexible solutions for those who need customized products or services to meet their specific research needs.",
    tableItems: {
      1: {
        key: "Collaborative Research Projects",
        value:
          "We are eager to explore co-development opportunities with other innovators in the life sciences field. Contact us to discuss potential partnerships.",
      },
      2: {
        key: "Custom Product Requests",
        value:
          "We offer tailored solutions, including custom proteins and kits, to meet the unique demands of your research. Reach out to our team for more information.",
      },
    },
  },
  contactInformation: {
    title: "Information",
    description:
      "Contact Us - We are always here to help, whether you need assistance with an order or have technical questions. Our support team is ready to assist you.",
    contact: {
      0: {
        title: "General Inquiries",
        email: "general_inquiries@genotic.com",
      },
      1: {
        title: "Technical Support",
        email: "technical_support@genotic.com",
      },
    },
  },
  faq: {
    title: "Frequently Asked Questions",
    technical: {
      title: "Technical",
      qAndA: {
        1: {
          question: "What are the recommended storage conditions for your products?",
          answer:
            "All lyophilized products should be stored at room temperature. For products in liquid form, storage at 4 °C or in a freezer is recommended. Please refer to the detailed product sheet for specific storage instructions for each product.",
        },
        2: {
          question:
            "How do I obtain Certificates of Analysis (COA) or Safety Data Sheets (SDS) for your products?",
          answer:
            "All necessary documents, including the Safety Data Sheet, Data Sheet, and Certificate of Analysis, are directly available on each product's page. These documents can be accessed and downloaded for your convenience.",
        },
        3: {
          question: "What quality control measures are in place for ensuring product consistency?",
          answer:
            "We implement rigorous quality control measures for each product batch. This includes gel electrophoresis to assess purity and molecular weight, Biolayer Interferometry (BLI) to measure protein interactions, and Surface Plason Resonance (SPR) analysis for certain proteins. For enzymes, we perform enzymatic activity assays specific to each enzyme, as detailed in the product's safety data sheet. Additionally, we conduct SDS-PAGE and, for some proteins, Western Blot analyses to ensure the highest quality.",
        },
        4: {
          question: "How do I troubleshoot if a product doesn’t work as expected?",
          answer:
            "Please contact our technical support team for assistance. We are here to help you resolve any issues and ensure optimal performance of our products.",
        },
        5: {
          question: "Do you offer custom formulations or modifications of your products?",
          answer:
            'Yes, we offer custom formulations and modifications of our products. All relevant information can be found in the "Services" section on our website. For additional information, please contact us via email at sales@genotic.com.',
        },
        6: {
          question:
            "Are your products validated for specific research applications (e.g., immunoassays, diagnostics)?",
          answer:
            "We are currently in the process of validating our products for specific applications. In the near future, we will provide detailed information indicating which products are suitable for applications like Western Blot and immunohistochemistry. Please stay tuned for updates.",
        },
        7: {
          question: "How should I handle temperature-sensitive products?",
          answer:
            "Please follow the specific storage instructions provided in the product sheet. Generally, liquid products should be stored at 4 °C or in the freezer to maintain their stability. Proper storage ensures product efficacy and longevity.",
        },
        8: {
          question:
            "Can I access protocols, usage guides, or technical documentation for your products?",
          answer:
            "At this time, we do not offer protocols or user guides, but we plan to add them in the future. However, we provide technical documentation and a collection of data aggregated from scientific papers, which can be found on the relevant product pages. We are committed to supporting your research needs and will continuously update our resources.",
        },
        9: {
          question: "What is the shelf life of your products, and how is it determined?",
          answer:
            "The shelf life of our products depends on the production method and storage conditions. For lyophilized products, the shelf life is approximately 12 months when stored in a cool, dark place. For frozen products, specific shelf life information will be provided. As our proteins are newly developed, we are conducting ongoing stability studies to provide precise shelf life data.",
        },
        10: {
          question: "What is your batch-to-batch consistency process?",
          answer:
            "We ensure exceptional batch-to-batch consistency by producing our antibodies through genetic modification of cells and establishing stable cell lines, eliminating the use of animals in production. We also utilize chemically defined media without any animal-derived substrates, guaranteeing high reproducibility and consistent quality across all batches.",
        },
        11: {
          question: "How do you ensure the reproducibility of results using your products?",
          answer:
            "By employing consistent production methods and using chemically defined media, we guarantee high reproducibility of our products. Our stringent quality control processes ensure that you can achieve reliable and consistent results in your research.",
        },
        12: {
          question: "What should I do if I need help with product integration into my workflow?",
          answer:
            "Please contact our technical support team for personalized assistance. Our experts are ready to help you integrate our products into your workflow and address any questions or concerns you may have.",
        },
      },
    },
    nonTechnical: {
      title: "Non-Technical",
      qAndA: {
        1: {
          question: "What is your return and refund policy?",
          answer:
            "We offer a 30-day return policy on all our products. If you are not completely satisfied—whether the product is unused or did not perform as expected—we provide a full refund without any questions asked. We are confident in the quality of our products and stand behind them fully. Your satisfaction is our top priority.",
        },
        2: {
          question: "What are your shipping options, and how long does delivery take?",
          answer:
            "We offer various shipping options to meet your needs. Delivery times depend on the specific product and your location. Exact delivery estimates are provided on each product's page, with real-time stock updates to ensure accurate availability.",
        },
        3: {
          question: "Do you ship internationally, and are there any restrictions?",
          answer:
            "Yes, we ship internationally. To place an international order, please contact us for a personalized quote and to discuss any potential restrictions based on your country's import regulations. We are dedicated to facilitating global access to our products while complying with all applicable laws and standards.",
        },
        4: {
          question: "How do I track my order after it has been shipped?",
          answer:
            "Once your order has been dispatched, we will send you a tracking number via email. You can use this number to monitor your shipment's progress through the carrier's tracking system. If you need assistance with tracking your order, please do not hesitate to contact us.",
        },
        5: {
          question: "What payment methods do you accept?",
          answerHTML:
            '{"time": 1727873139304, "blocks": [{"id": "Jrq99IXsgB", "data": {"text": "We accept the following payment methods:"}, "type": "paragraph"}, {"id": "-0L4jom3aw", "data": {"items": ["Bank Transfers", "Credit and Debit Card Payments", "Local Payment Methods Supported by Stripe"], "style": "unordered"}, "type": "list"}], "version": "2.24.3"}',
        },
        6: {
          question: "How do I request a quote for bulk or custom orders?",
          answer: "For bulk orders, please contact us via email at sales@genotic.com ",
        },
        7: {
          question:
            "Do you offer discounts for academic institutions, non-profits, or bulk purchases?",
          answer:
            "Yes, we offer discounts for bulk orders, academic institutions, and non-profit organizations. We can provide coupons that grant discounts. Please contact us to discuss how we can accommodate your needs.",
        },
        8: {
          question: "Can I open a credit account with your company?",
          answer:
            "Yes, we offer credit accounts to companies that we have an established cooperation with. Once we see that everything is working well, we can arrange post-order payment terms. Please contact us to discuss the application process.",
        },
        9: {
          question: "What is your warranty policy for products?",
          answer:
            "We stand behind the quality and reliability of our products. Each product comes with detailed information and research documentation outlining the specific tests and quality checks performed. Our products are consistent across batches because we utilize animal-free production methods. If you encounter any issues, we offer a full refund or replacement. Our warranty policy reflects our commitment to providing dependable, high-quality products that meet your research needs.",
        },
        10: {
          question: "Are you involved in any sustainability or ethical sourcing initiatives?",
          answer:
            "While we are a small company and do not officially participate in sustainability initiatives yet, we are committed to responsible practices. We are implementing renewable energy solutions, aiming to operate off-grid. Additionally, we do not use animals in our antibody design.",
        },
        11: {
          question: "Can I schedule a product demonstration or consultation?",
          answer:
            "Yes, we would be pleased to arrange a product demonstration or consultation with you. We can schedule video calls to discuss our products in detail and address any questions you may have. Please email us at general_inquires@genotic.com to set up a convenient time.",
        },
        12: {
          question: "How do I update my account information or manage my orders online?",
          answer:
            "You can update your account information and manage your orders by logging into your account on our website. Everything is accessible through the user panel, where you can edit personal details, review your order history, and track current orders. ",
        },
        13: {
          question: "Who do I contact for general customer support or technical assistance?",
          answer:
            "For general customer support or technical assistance, please look below for relevant contact information.",
        },
      },
    },
  },
};

export type SupportBase = typeof support;

export default support;
