import type { EnErrorBase } from "../en/error";
import type { ErrorBase } from "../types";

const errorType = {
  NO_CHECKOUT_ID: "Sin ID de pago",
  NO_CHECKOUT: "Sin pago",
  UNAUTHORIZED: "No autorizado",
} satisfies ErrorBase;

const error = {
  type: errorType,
  somethingWentWrong: "Algo salió mal",
  backToHome: "Volver a la página de inicio",
  "404": "No se pudo encontrar esta página",
  passwordsDoNotMatch: "Las contraseñas no coinciden",
} satisfies EnErrorBase;

export default error;
