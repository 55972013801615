import type { EnCountries } from "../en/countries";

export default {
  US: "Estados Unidos",
  CA: "Canadá",
  GB: "Reino Unido",
  DE: "Alemania",
  FR: "Francia",
  JP: "Japón",
  AU: "Australia",
  CN: "China",
  IN: "India",
  BR: "Brasil",
  MX: "México",
  ES: "España",
  IT: "Italia",
  NL: "Países Bajos",
  RU: "Rusia",
  PL: "Polonia",
  CZ: "República Checa",
  SK: "Eslovaquia",
} satisfies EnCountries;
