import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

const en = {
  support,
  saleor,
  error,
  zodI18n,
  countries,
  title: "Genotic",
  common: {
    cancel: "Cancel",
    close: "Close",
    text: "Text",
    edit: "Edit",
    save: "Save",
    add: "Add",
    show: "Show",
    showMore: "Show more",
    showLess: "Show less",
    hide: "Hide",
    open: "Open",
    day: "Day | Days",
    download: "Download | Downloads",
    delete: "Delete",
    search: "Search",
    scrollToTop: "Scroll to top",
    or: "or",
    submit: "Submit",
    submitting: "Submitting",
  },
  unit: {
    pc: "pc",
  },
  footer: {
    termsAndConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    new: "New",
    hiring: "We're hiring!",
  },
  header: {
    all: "All",
    myAccount: "My account",
    cart: "Cart",
    signOut: "Sign out",
    languageDisplay: "English (USD)",
    countryWithCurrency: "United States (USD)",
    search: "Search",
    menu: "Menu",
  },
  search: {
    searchResultsInIndividualCategories: "Search results in individual categories",
    closeSearchResults: "Close search results",
    resultsInIndividualCategories: "Results in individual categories",
  },
  profile: {
    title: "Profile",
    info: "Info",
    addresses: "Addresses",
    giftCards: "Gift cards",
    editProfile: "Edit profile",
    orders: {
      title: "Orders",
      empty: "You didn't create any orders",
    },
  },
  address: {
    title: "Address",
    shippingAddress: "Shipping address | Shipping addresses",
    billingAddress: "Billing address | Billing addresses",
    defaultAddress: "Default address",
    thisAddressIsUsedForBothShippingAndBilling:
      "This address is used for both shipping and billing",
    defaultForShippingAndBilling: "Default for shipping and billing",
    defaultShippingAddress: "Default shipping address",
    defaultBillingAddress: "Default billing address",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Address line 1",
    streetAddress2: "Address line 2",
    postalCode: "Postal code",
    city: "City",
    cityArea: "City area",
    email: "@:auth.email",
    country: "Country",
    countryArea: "State / Province",
    vat: "VAT",
    selectACountry: "Select a country",
    companyName: "Company/Institution",
    phoneNumber: "Phone number",
    billingMatchesShippingAddress: "Billing address matches shipping address",
    noAddress: "No address",
    noDefaultAddress: "No default address",
    empty: "You don't have any addresses",
    editAddress: "Edit address",
    addAddress: "Add address",
    deleteAddress: "Delete address",
    organizationInfo: "Organization info",
  },
  order: {
    cart: {
      empty: "Your cart is empty",
      title: "Cart",
      adjustItemQuantity: "Adjust item quantity",
      shippingFrom: "Shipping from {amount}",
    },
    checkout: {
      title: "Checkout",
      addAnythingToCartToContinue: "Add anything to your cart to continue",
      goBackToStore: "Go back to store",
      delivery: "Delivery",
      completeCheckout: "Complete checkout",
      haveAPromoCode: "Have a promo code?",
      promoCode: "Promo code",
      specifyHowYouWantToPlaceYourOrder: "Specify how you want to place your order",
      continueAsGuest: "Continue as guest",
      continueAsGuestDescription:
        "In every moment of the ordering process you can create a free member profile",
      iHaveAnAccount: "I have an account",
      createAccount: "Create account",
      continueAsMember: "Continue as member",
    },
    steps: {
      address: "Address",
      shipping: "Shipping",
      payment: "Payment",
      continue: "Continue",
      back: "Back",
    },
    paymentMethods: {
      title: "Payment methods",
      bankTransfer: "Bank transfer",
    },
    number: "Order number",
    status: "Status",
    paid: "Paid",
    unpaid: "Unpaid",
    paymentStatus: "Payment status",
    complete: "Complete order",
    quantity: "Quantity",
    info: "Ordering info",
    arrives: "Arrives",
    addToCart: "Add to cart",
    addedToCart: "Product {product} was added to your cart",
    goToCart: "Go to cart",
    goToCheckout: "Go to checkout",
    shippingTo: "Shipping to",
    total: "Total",
    subtotal: "Subtotal",
    shipping: {
      title: "Shipping",
      country: "United States of America",
      noShippingMethodsAvailable: "No shipping methods available",
      shippingMethods: "Shipping methods",
    },
    details: {
      title: "Order details",
      qty: "Qty",
    },
  },
  auth: {
    login: "Log in",
    register: "Sign up",
    logout: "Log out",
    email: "E-mail",
    password: "Password",
    password2: "Repeat password",
    firstName: "First name",
    lastName: "Last name",
    forgotPassword: "Forgot password",
    createNewAccount: "Create new account",
    alreadyHaveAnAccount: "Already have an account?",
    dontHaveAnAccount: "Don't have an account?",
  },
  product: {
    title: "Product | Products",
    addToCart: "Add to cart",
    addedToCart: {
      toastTitle: "Product {product} was added to your cart",
      toastDescription: "You can now view it there ...",
    },
    outOfStock: "Out of stock",
    synonyms: "Synonyms",
    description: {
      recommendedDilution: "Recommended dilution",
      applications: "Application | Applications",
      dilution: "Dilution",
      testedApplications: "Tested applications",
      targetSpecies: "Target species",
      targetAliases: "Target aliases",
      species: "Species",
      uniProtIdAndGenId: "UniProt ID and Gen ID",
      images: "Image | Images",
      publications: "Publication | Publications",
      authors: "Author | Authors",
      performanceData: "Performance data",
      support: "Support", // it was removed in SupportSection but might be used somewhere else
      files: "Files",
      productSpecificProtocols: "Product specific protocols",
      frequentlyAskedQuestions: "Frequently asked questions",
      contact: "Contact",
      productDetails: "Product details",
      targetInfo: "Target info",
      relatedProducts: "Related product | Related products",
    },
    noProductsFound: "No products found",
  },
  ui: {
    availability: {
      available: "Available",
      unavailable: "Unavailable",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "@:categories.title",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Categories",
    noCategoriesFound: "No categories found",
  },
  warning: {
    thisActionCannotBeUndone: "This action cannot be undone",
  },
};

export type I18nLocale = typeof en;

export default en;
