import type { EnCountries } from "../en/countries";

export default {
  US: "États-Unis",
  CA: "Canada",
  GB: "Royaume-Uni",
  DE: "Allemagne",
  FR: "France",
  JP: "Japon",
  AU: "Australie",
  CN: "Chine",
  IN: "Inde",
  BR: "Brésil",
  MX: "Mexique",
  ES: "Espagne",
  IT: "Italie",
  NL: "Pays-Bas",
  RU: "Russie",
  PL: "Pologne",
  CZ: "République tchèque",
  SK: "Slovaquie",
} satisfies EnCountries;
