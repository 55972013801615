import type { Saleor, SaleorErrors } from "../en/saleor";

const errors = {
  CheckoutError: {
    VOUCHER_NOT_APPLICABLE: "Kupon nie może być zastosowany",
    REQUIRED: "Wymagane",
    NO_LINES: "Nie dodano produktów",
    INVALID: {
      text: "Nieprawidłowy | Nieprawidłowe",
      phone: "Nieprawidłowy @.lower:address.phoneNumber",
      countryArea: "Nieprawidłowy @.lower:address.countryArea",
      postalCode: "Nieprawidłowy @.lower:address.postalCode",
    },
    INVALID_SHIPPING_METHOD: "Nieprawidłowy sposób dostawy",
    QUANTITY_GREATER_THAN_LIMIT: "Ilość większa niż limit",
    EMAIL_NOT_SET: "Email nie ustawiony",
    NOT_FOUND: "Nie znaleziono",
    PRODUCT_NOT_PUBLISHED: "Produkt nie opublikowany",
    PRODUCT_UNAVAILABLE_FOR_PURCHASE: "Produkt nie dostępny do zakupu",
    INSUFFICIENT_STOCK: "Niedostateczny stan magazynowy",
    ZERO_QUANTITY: "Ilość do zera",
    UNAVAILABLE_VARIANT_IN_CHANNEL: "Wariant nie dostępny w kanale",
  },
  common: {
    UNIQUE: "Unikalny",
    INVALID: "Nieprawidłowy",
    NOT_FOUND: "Nie znaleziono",
  },
  AccountError: {
    INVALID_CREDENTIALS: "Nieprawidłowe dane logowania",
    INVALID_PASSWORD: "Nieprawidłowe hasło",
    NOT_FOUND: "Nie znaleziono",
    PASSWORD_TOO_SHORT: "Hasło za krótkie",
    PASSWORD_TOO_COMMON: "Hasło zbyt popularne",
    PASSWORD_TOO_SIMILAR: "Hasło zbyt podobne",
    REQUIRED: "Wymagane",
    UNIQUE: "Unikalny",
    ACCOUNT_NOT_CONFIRMED: "Konto niepotwierdzone",
  },
  TransactionInitializeError: {
    CHECKOUT_COMPLETION_IN_PROGRESS: "Zakończenie zakupu w toku",
  },
  TransactionProcessError: {
    NOT_FOUND: "Transakcja nie znaleziona",
    TRANSACTION_ALREADY_PROCESSED: "Transakcja już przetworzona",
    INVALID: "Nieprawidłowy",
    CHECKOUT_COMPLETION_IN_PROGRESS: "Zakończenie zakupu w toku",
  },
} satisfies SaleorErrors;

export default {
  errors,
  order: {
    Status: {
      CANCELED: "Anulowane",
      DRAFT: "Borrador",
      EXPIRED: "Wygasłe",
      FULFILLED: "Wykonane",
      PARTIALLY_FULFILLED: "Częściowe wykonanie",
      PARTIALLY_RETURNED: "Częściowe zwrot",
      RETURNED: "Zwrócone",
      UNCONFIRMED: "Niepotwierdzone",
      UNFULFILLED: "Niewykonane",
    },
    ChargeStatus: {
      FULL: "Pełne",
      NONE: "Brak",
      OVERCHARGED: "Przekroczone",
      PARTIAL: "Częściowe",
    },
  },
  payment: {
    ChargeStatus: {
      CANCELLED: "Anulowane",
      FULLY_CHARGED: "Pełne",
      FULLY_REFUNDED: "Pełny zwrot",
      NOT_CHARGED: "Nieopłacone",
      PARTIALLY_CHARGED: "Częściowo opłacone",
      PARTIALLY_REFUNDED: "Częściowy zwrot",
      PENDING: "Oczekujące",
      REFUSED: "Odrzucone",
    },
  },
} satisfies Saleor;
