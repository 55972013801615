import type { ErrorBase } from "../types";

const errorType = {
  NO_CHECKOUT_ID: "No checkout ID",
  NO_CHECKOUT: "No checkout",
  UNAUTHORIZED: "Unauthorized",
} satisfies ErrorBase;

const error = {
  type: errorType,
  somethingWentWrong: "Something went wrong",
  backToHome: "Back to homepage",
  "404": "This page could not be found",
  passwordsDoNotMatch: "Passwords do not match",
};

export type EnErrorBase = typeof error;

export default error;
