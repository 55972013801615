import type { EnCountries } from "../en/countries";

export default {
  US: "Stany Zjednoczone",
  CA: "Kanada",
  GB: "Wielka Brytania",
  DE: "Niemcy",
  FR: "Francja",
  JP: "Japonia",
  AU: "Australia",
  CN: "Chiny",
  IN: "Indie",
  BR: "Brazylia",
  MX: "Meksyk",
  ES: "Hiszpania",
  IT: "Włochy",
  NL: "Holandia",
  RU: "Rosja",
  PL: "Polska",
  CZ: "Czechy",
  SK: "Słowacja",
} satisfies EnCountries;
