import type { SupportBase } from "../en/support";

export default {
  title: "Support",
  subtitle:
    "Obtenez des données cohérentes et reproductibles avec notre gamme complète de protéines.",
  description:
    "Chez Genotic, nous nous engageons à fournir le support et les ressources dont vous avez besoin pour tirer le meilleur parti de nos produits. Notre équipe de support est là pour vous assurer l'accès aux outils, informations et conseils nécessaires pour obtenir des résultats satisfaisants.",
  sidebar: {
    technicalSupport: "Support Technique",
    productDocumentation: "Documentation Produit",
    orderingAndPaymentSupport: "Support Commande et Paiement",
    shippingAndDelivery: "Expédition et Livraison",
    returnsAndRefunds: "Retours et Remboursements",
    collaborationsAndCustomOrders: "Collaborations et Commandes Personnalisées",
    contactInformation: "Informations de Contact",
    FAQ: "FAQ",
  },
  technicalSupport: {
    title: "Support Technique",
    description:
      "Nous offrons un support technique complet pour tous nos produits. Notre équipe compétente est prête à vous aider pour toute question ou dépannage. Pour obtenir de l'aide sur les détails des produits, veuillez consulter notre Base de Connaissances ou contacter directement notre équipe de support technique.",
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "support_technique@genotic.com",
      },
    },
  },
  productDocumentation: {
    title: "Documentation Produit (À confirmer)",
    description:
      "Nous offrons un support technique complet pour tous nos produits. Notre équipe compétente est prête à vous aider pour toute question ou dépannage. Pour obtenir de l'aide sur les détails des produits, veuillez consulter notre Base de Connaissances ou contacter directement notre équipe de support technique.",
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "support_technique@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Support Commande et Paiement",
    description:
      "Nous visons à rendre le processus de commande simple et direct. Que vous achetiez un seul article ou passiez une commande en gros, nous proposons diverses options adaptées à vos besoins.",
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "support_technique@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Expédition et Livraison",
    description:
      "Nos produits sont expédiés dans le monde entier avec un accent sur une livraison rapide et fiable. Nous collaborons avec des transporteurs de confiance pour garantir que votre commande arrive dans des conditions optimales.",
    tableItems: {
      1: {
        key: "Options d'Expédition",
        value: "À confirmer",
      },
      2: {
        key: "Suivi de Commande",
        value: "Suivez votre expédition en temps réel une fois qu'elle est envoyée.",
      },
      3: {
        key: "Expédition Internationale",
        value: "À confirmer",
      },
    },
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "support_technique@genotic.com",
      },
    },
  },
  returnsAndRefunds: {
    title: "Retours et Remboursements",
    description:
      "Nous nous efforçons de fournir des produits de haute qualité, mais si vous devez retourner un article ou demander un remboursement, nous sommes là pour vous aider",
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "support_technique@genotic.com",
      },
    },
  },
  collaborationsAndCustomOrders: {
    title: "Collaborations et Commandes Personnalisées",
    description:
      "Genotic recherche activement des collaborations avec des institutions de recherche, des entreprises de biotechnologie et des partenaires industriels. Nous proposons des solutions flexibles pour ceux qui ont besoin de produits ou de services personnalisés pour répondre à leurs besoins de recherche spécifiques.",
    tableItems: {
      1: {
        key: "Projets de Recherche Collaborative",
        value:
          "Nous sommes désireux d'explorer des opportunités de co-développement avec d'autres innovateurs dans le domaine des sciences de la vie. Contactez-nous pour discuter de partenariats potentiels.",
      },
      2: {
        key: "Demandes de Produits Personnalisés",
        value:
          "Nous proposons des solutions sur mesure, y compris des protéines et des kits personnalisés, pour répondre aux exigences uniques de votre recherche. Contactez notre équipe pour plus d'informations.",
      },
    },
  },
  contactInformation: {
    title: "Informations de Contact",
    description:
      "Contactez-nous - Nous sommes toujours là pour vous aider, que vous ayez besoin d'assistance pour une commande ou que vous ayez des questions techniques. Notre équipe de support est prête à vous assister.",
    contact: {
      0: {
        title: "Renseignements Généraux",
        email: "renseignements_generaux@genotic.com",
      },
      1: {
        title: "Support Technique",
        email: "support_technique@genotic.com",
      },
    },
  },
  faq: {
    title: "Questions Fréquemment Posées",
    technical: {
      title: "Technique",
      qAndA: {
        1: {
          question: "Quelles sont les conditions de stockage recommandées pour vos produits ?",
          answer:
            "Tous les produits lyophilisés doivent être conservés à température ambiante. Pour les produits sous forme liquide, un stockage à 4 °C ou au congélateur est recommandé. Veuillez vous référer à la fiche détaillée du produit pour les instructions de stockage spécifiques à chaque produit.",
        },
        2: {
          question:
            "Comment puis-je obtenir les Certificats d'Analyse (COA) ou les Fiches de Données de Sécurité (FDS) pour vos produits ?",
          answer:
            "Tous les documents nécessaires, y compris la Fiche de Données de Sécurité, la Fiche Technique et le Certificat d'Analyse, sont directement disponibles sur la page de chaque produit. Ces documents peuvent être consultés et téléchargés pour votre commodité.",
        },
        3: {
          question:
            "Quelles mesures de contrôle qualité sont en place pour assurer la cohérence des produits ?",
          answer:
            "Nous mettons en œuvre des mesures de contrôle qualité rigoureuses pour chaque lot de produits. Cela comprend l'électrophorèse sur gel pour évaluer la pureté et le poids moléculaire, l'Interférométrie Bio-couche (BLI) pour mesurer les interactions protéiques, et l'analyse par Résonance Plasmonique de Surface (SPR) pour certaines protéines. Pour les enzymes, nous effectuons des tests d'activité enzymatique spécifiques à chaque enzyme, comme détaillé dans la fiche de données de sécurité du produit. De plus, nous réalisons des analyses SDS-PAGE et, pour certaines protéines, des analyses Western Blot pour garantir la plus haute qualité.",
        },
        4: {
          question:
            "Comment puis-je résoudre les problèmes si un produit ne fonctionne pas comme prévu ?",
          answer:
            "Veuillez contacter notre équipe de support technique pour obtenir de l'aide. Nous sommes là pour vous aider à résoudre tous les problèmes et assurer une performance optimale de nos produits.",
        },
        5: {
          question:
            "Proposez-vous des formulations personnalisées ou des modifications de vos produits ?",
          answer:
            'Oui, nous proposons des formulations personnalisées et des modifications de nos produits. Toutes les informations pertinentes se trouvent dans la section "Services" de notre site web. Pour plus d\'informations, veuillez nous contacter par e-mail à sales@genotic.com.',
        },
        6: {
          question:
            "Vos produits sont-ils validés pour des applications de recherche spécifiques (par exemple, immunoessais, diagnostics) ?",
          answer:
            "Nous sommes actuellement en train de valider nos produits pour des applications spécifiques. Dans un avenir proche, nous fournirons des informations détaillées indiquant quels produits conviennent à des applications telles que le Western Blot et l'immunohistochimie. Veuillez rester à l'écoute pour les mises à jour.",
        },
        7: {
          question: "Comment dois-je manipuler les produits sensibles à la température ?",
          answer:
            "Veuillez suivre les instructions de stockage spécifiques fournies dans la fiche produit. En général, les produits liquides doivent être conservés à 4 °C ou au congélateur pour maintenir leur stabilité. Un stockage approprié garantit l'efficacité et la longévité du produit.",
        },
        8: {
          question:
            "Puis-je accéder aux protocoles, guides d'utilisation ou à la documentation technique pour vos produits ?",
          answer:
            "Pour le moment, nous ne proposons pas de protocoles ou de guides d'utilisation, mais nous prévoyons de les ajouter à l'avenir. Cependant, nous fournissons une documentation technique et une collection de données agrégées à partir d'articles scientifiques, qui peuvent être trouvées sur les pages des produits pertinents. Nous nous engageons à soutenir vos besoins de recherche et mettrons continuellement à jour nos ressources.",
        },
        9: {
          question:
            "Quelle est la durée de conservation de vos produits et comment est-elle déterminée ?",
          answer:
            "La durée de conservation de nos produits dépend de la méthode de production et des conditions de stockage. Pour les produits lyophilisés, la durée de conservation est d'environ 12 mois lorsqu'ils sont conservés dans un endroit frais et sombre. Pour les produits congelés, des informations spécifiques sur la durée de conservation seront fournies. Comme nos protéines sont nouvellement développées, nous menons des études de stabilité en cours pour fournir des données précises sur la durée de conservation.",
        },
        10: {
          question: "Quel est votre processus de cohérence entre les lots ?",
          answer:
            "Nous assurons une cohérence exceptionnelle entre les lots en produisant nos anticorps par modification génétique des cellules et en établissant des lignées cellulaires stables, éliminant ainsi l'utilisation d'animaux dans la production. Nous utilisons également des milieux chimiquement définis sans aucun substrat d'origine animale, garantissant une haute reproductibilité et une qualité constante pour tous les lots.",
        },
        11: {
          question: "Comment assurez-vous la reproductibilité des résultats avec vos produits ?",
          answer:
            "En employant des méthodes de production cohérentes et en utilisant des milieux chimiquement définis, nous garantissons une haute reproductibilité de nos produits. Nos processus de contrôle qualité rigoureux assurent que vous pouvez obtenir des résultats fiables et cohérents dans votre recherche.",
        },
        12: {
          question:
            "Que dois-je faire si j'ai besoin d'aide pour intégrer le produit dans mon flux de travail ?",
          answer:
            "Veuillez contacter notre équipe de support technique pour une assistance personnalisée. Nos experts sont prêts à vous aider à intégrer nos produits dans votre flux de travail et à répondre à toutes vos questions ou préoccupations.",
        },
      },
    },
    nonTechnical: {
      title: "Non-Technique",
      qAndA: {
        1: {
          question: "Quelle est votre politique de retour et de remboursement ?",
          answer:
            "Nous offrons une politique de retour de 30 jours sur tous nos produits. Si vous n'êtes pas entièrement satisfait - que le produit soit inutilisé ou n'ait pas fonctionné comme prévu - nous offrons un remboursement complet sans poser de questions. Nous sommes confiants dans la qualité de nos produits et les soutenons pleinement. Votre satisfaction est notre priorité absolue.",
        },
        2: {
          question:
            "Quelles sont vos options d'expédition et combien de temps prend la livraison ?",
          answer:
            "Nous proposons diverses options d'expédition pour répondre à vos besoins. Les délais de livraison dépendent du produit spécifique et de votre localisation. Des estimations de livraison précises sont fournies sur la page de chaque produit, avec des mises à jour en temps réel des stocks pour assurer une disponibilité précise.",
        },
        3: {
          question: "Expédiez-vous à l'international, et y a-t-il des restrictions ?",
          answer:
            "Oui, nous expédions à l'international. Pour passer une commande internationale, veuillez nous contacter pour un devis personnalisé et pour discuter des restrictions potentielles basées sur les réglementations d'importation de votre pays. Nous nous engageons à faciliter l'accès mondial à nos produits tout en respectant toutes les lois et normes applicables.",
        },
        4: {
          question: "Comment puis-je suivre ma commande après son expédition ?",
          answer:
            "Une fois votre commande expédiée, nous vous enverrons un numéro de suivi par e-mail. Vous pouvez utiliser ce numéro pour suivre la progression de votre envoi via le système de suivi du transporteur. Si vous avez besoin d'aide pour suivre votre commande, n'hésitez pas à nous contacter.",
        },
        5: {
          question: "Quels modes de paiement acceptez-vous ?",
          answerHTML:
            '{"time": 1727873139304, "blocks": [{"id": "Jrq99IXsgB", "data": {"text": "Nous acceptons les modes de paiement suivants :"}, "type": "paragraph"}, {"id": "-0L4jom3aw", "data": {"items": ["Virements bancaires", "Paiements par carte de crédit et de débit", "Méthodes de paiement locales prises en charge par Stripe"], "style": "unordered"}, "type": "list"}], "version": "2.24.3"}',
        },
        6: {
          question:
            "Comment puis-je demander un devis pour des commandes en gros ou personnalisées ?",
          answer:
            "Pour les commandes en gros, veuillez nous contacter par e-mail à sales@genotic.com ",
        },
        7: {
          question:
            "Offrez-vous des réductions pour les institutions académiques, les organisations à but non lucratif ou les achats en gros ?",
          answer:
            "Oui, nous offrons des réductions pour les commandes en gros, les institutions académiques et les organisations à but non lucratif. Nous pouvons fournir des coupons qui accordent des réductions. Veuillez nous contacter pour discuter de la façon dont nous pouvons répondre à vos besoins.",
        },
        8: {
          question: "Puis-je ouvrir un compte de crédit auprès de votre entreprise ?",
          answer:
            "Oui, nous proposons des comptes de crédit aux entreprises avec lesquelles nous avons établi une coopération. Une fois que nous constatons que tout fonctionne bien, nous pouvons organiser des conditions de paiement après commande. Veuillez nous contacter pour discuter du processus de demande.",
        },
        9: {
          question: "Quelle est votre politique de garantie pour les produits ?",
          answer:
            "Nous garantissons la qualité et la fiabilité de nos produits. Chaque produit est accompagné d'informations détaillées et d'une documentation de recherche décrivant les tests spécifiques et les contrôles de qualité effectués. Nos produits sont cohérents d'un lot à l'autre car nous utilisons des méthodes de production sans animaux. Si vous rencontrez des problèmes, nous offrons un remboursement complet ou un remplacement. Notre politique de garantie reflète notre engagement à fournir des produits fiables et de haute qualité qui répondent à vos besoins de recherche.",
        },
        10: {
          question:
            "Êtes-vous impliqués dans des initiatives de durabilité ou d'approvisionnement éthique ?",
          answer:
            "Bien que nous soyons une petite entreprise et que nous ne participions pas encore officiellement à des initiatives de durabilité, nous nous engageons dans des pratiques responsables. Nous mettons en place des solutions d'énergie renouvelable, visant à fonctionner hors réseau. De plus, nous n'utilisons pas d'animaux dans la conception de nos anticorps.",
        },
        11: {
          question: "Puis-je planifier une démonstration de produit ou une consultation ?",
          answer:
            "Oui, nous serions ravis d'organiser une démonstration de produit ou une consultation avec vous. Nous pouvons planifier des appels vidéo pour discuter en détail de nos produits et répondre à toutes vos questions. Veuillez nous envoyer un e-mail à general_inquires@genotic.com pour fixer un moment qui vous convient.",
        },
        12: {
          question:
            "Comment puis-je mettre à jour les informations de mon compte ou gérer mes commandes en ligne ?",
          answer:
            "Vous pouvez mettre à jour les informations de votre compte et gérer vos commandes en vous connectant à votre compte sur notre site web. Tout est accessible via le panneau utilisateur, où vous pouvez modifier vos détails personnels, consulter l'historique de vos commandes et suivre les commandes en cours.",
        },
        13: {
          question:
            "Qui dois-je contacter pour le support client général ou l'assistance technique ?",
          answer:
            "Pour le support client général ou l'assistance technique, veuillez consulter ci-dessous les informations de contact pertinentes.",
        },
      },
    },
  },
} satisfies SupportBase;
