import type { CountryCode } from "@graphql";

const smth = {
  US: "United States",
  CA: "Canada",
  GB: "United Kingdom",
  DE: "Germany",
  FR: "France",
  JP: "Japan",
  AU: "Australia",
  CN: "China",
  IN: "India",
  BR: "Brazil",
  MX: "Mexico",
  ES: "Spain",
  IT: "Italy",
  NL: "Netherlands",
  RU: "Russia",
  PL: "Poland",
  CZ: "Czech Republic",
  SK: "Slovakia",
} satisfies Partial<Record<CountryCode, string>>;

export type EnCountries = typeof smth;

export default smth;
