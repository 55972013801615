import type { I18nLocale } from "../en";
import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

export default {
  saleor,
  error,
  support,
  countries,
  zodI18n,
  title: "Genotic",
  common: {
    cancel: "Cancelar",
    close: "Cerrar",
    text: "Texto",
    showMore: "Mostrar más",
    showLess: "Mostrar menos",
    edit: "Editar",
    save: "Guardar",
    add: "Agregar",
    day: "Día | Días",
    show: "Mostrar",
    hide: "Ocultar",
    download: "Descargar | Descargas",
    search: "Buscar",
    scrollToTop: "Ir arriba",
    delete: "Eliminar",
    or: "o",
    submit: "Enviar",
    submitting: "Enviando",
    open: "Ouvrir",
  },
  unit: {
    pc: "ud",
  },
  footer: {
    termsAndConditions: "Términos y condiciones",
    privacyPolicy: "Política de privacidad",
    new: "Nuevo",
    hiring: "Estamos contratando!",
  },
  header: {
    all: "Todo",
    myAccount: "Mi cuenta",
    cart: "Carrito",
    signOut: "Cerrar sesión",
    languageDisplay: "Español (EUR)",
    countryWithCurrency: "Brasil (EUR)",
    menu: "Menú",
    search: "Buscar",
  },
  search: {
    searchResultsInIndividualCategories: "Resultados de búsqueda en categorías individuales",
    closeSearchResults: "Cerrar resultados de búsqueda",
    resultsInIndividualCategories: "Resultados en categorías individuales",
  },
  profile: {
    title: "Perfil",
    info: "Información",
    addresses: "Direcciones",
    giftCards: "Tarjetas de regalo",
    orders: {
      title: "Pedidos",
      empty: "No has creado ningún pedido",
    },
    editProfile: "Editar perfil",
  },
  address: {
    title: "Dirección",
    shippingAddress: "Dirección de envío | Direcciones de envío",
    billingAddress: "Dirección de facturación | Direcciones de facturación",
    defaultAddress: "Dirección por defecto",
    thisAddressIsUsedForBothShippingAndBilling:
      "Esta dirección se utiliza para envíos y facturaciones",
    defaultForShippingAndBilling: "Por defecto para envíos y facturaciones",
    defaultShippingAddress: "Dirección de envío por defecto",
    defaultBillingAddress: "Dirección de facturación por defecto",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Línea de dirección 1",
    streetAddress2: "Línea de dirección 2",
    postalCode: "Código postal",
    city: "Ciudad",
    cityArea: "Área de la ciudad",
    email: "@:auth.email",
    vat: "VAT",
    country: "País",
    countryArea: "Estado / Provincia",
    selectACountry: "Selecciona un país",
    companyName: "Nombre de la empresa/institución",
    phoneNumber: "Número de teléfono",
    billingMatchesShippingAddress: "La dirección de facturación coincide con la dirección de envío",
    editAddress: "Editar dirección",
    deleteAddress: "Eliminar dirección",
    addAddress: "Agregar dirección",
    noAddress: "No hay dirección",
    noDefaultAddress: "No hay dirección por defecto",
    empty: "No has agregado ninguna dirección",
    organizationInfo: " Información de la organización",
  },
  order: {
    cart: {
      empty: "Tu carrito está vacío",
      title: "Carrito",
      adjustItemQuantity: "Ajustar la cantidad del artículo",
      shippingFrom: "Envío desde {amount}",
    },
    checkout: {
      title: "Pedido",
      addAnythingToCartToContinue: "Añade algo a tu carrito para continuar",
      goBackToStore: "Volver a la tienda",
      delivery: "Envío",
      completeCheckout: "Completar pedido",
      haveAPromoCode: "¿Tienes un código promocional?",
      promoCode: "Código promocional",
      specifyHowYouWantToPlaceYourOrder: "Especifica cómo quieres colocar tu pedido",
      continueAsGuest: "Continuar como invitado",
      continueAsGuestDescription:
        "En cada momento del proceso de pedido puedes crear un perfil de usuario gratuito",
      iHaveAnAccount: "Ya tengo una cuenta",
      createAccount: "Crear cuenta",
      continueAsMember: "Continuar como miembro",
    },
    steps: {
      address: "Dirección",
      shipping: "Envío",
      payment: "Pago",
      continue: "Continuar",
      back: "Atrás",
    },
    paymentMethods: {
      title: "Métodos de pago",
      bankTransfer: "Transferencia bancaria",
    },
    number: "Número de pedido",
    status: "Estado",
    paid: "Pagado",
    unpaid: "Pendiente",
    paymentStatus: "Estado de pago",
    complete: "Completado",
    quantity: "Cantidad",
    info: "Información de pedido",
    arrives: "llega",
    addToCart: "Añadir al carrito",
    addedToCart: "El producto {product} ha sido añadido a tu carrito",
    goToCart: "Ir al carrito",
    goToCheckout: "Ir a la caja",
    shippingTo: "Envío a",
    total: "Total",
    subtotal: "Subtotal",
    shipping: {
      title: "envío",
      country: "España",
      noShippingMethodsAvailable: "No hay métodos de envío disponibles",
      shippingMethods: "Métodos de envío",
    },
    details: {
      title: "Detalles del pedido",
      qty: "Cant.",
    },
  },
  auth: {
    login: "Iniciar sesión",
    register: "Registrarse",
    logout: "Cerrar sesión",
    email: "Correo electrónico",
    password: "Contraseña",
    password2: "Confirmar contraseña",
    firstName: "Nombre",
    lastName: "Apellido",
    alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
    dontHaveAnAccount: "¿No tienes una cuenta?",
    forgotPassword: "¿Olvidaste tu contraseña?",
    createNewAccount: "Crear nueva cuenta",
  },
  product: {
    title: "Producto | Productos",
    addToCart: "Añadir al carrito",
    addedToCart: {
      toastTitle: "El producto {product} ha sido añadido a tu carrito",
      toastDescription: "Ahora puedes verlo ahí...",
    },
    outOfStock: "Agotado",
    synonyms: "Sinónimos",
    noProductsFound: "No se han encontrado productos",
    description: {
      recommendedDilution: "Dilución recomendada",
      applications: "Aplicación | Aplicaciones",
      dilution: "Dilución",
      testedApplications: "Aplicaciones probadas",
      targetSpecies: "Especies objetivo",
      targetAliases: "Alias objetivo",
      species: "Especies",
      uniProtIdAndGenId: "ID UniProt y ID Genético",
      images: "Imagen | Imágenes",
      publications: "Publicación | Publicaciones",
      authors: "Autor | Autores",
      performanceData: "Datos de rendimiento",
      support: "Soporte",
      productSpecificProtocols: "Protocolos específicos del producto",
      frequentlyAskedQuestions: "Preguntas frecuentes",
      contact: "Contacto",
      productDetails: "Detalles del producto",
      targetInfo: "Información del objetivo",
      relatedProducts: "Producto relacionado | Productos relacionados",
      files: "archivos",
    },
  },
  ui: {
    availability: {
      available: "Disponible",
      unavailable: "No disponible",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Categoría | Categorías",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Categorías",
    noCategoriesFound: "No se han encontrado categorías",
  },
  warning: {
    thisActionCannotBeUndone: "Esta acción no se puede deshacer",
  },
} satisfies I18nLocale;
