import type { EnErrorBase } from "../en/error";

export default {
  type: {
    NO_CHECKOUT_ID: "Keine Checkout ID",
    NO_CHECKOUT: "Keine Checkout",
    UNAUTHORIZED: "Nicht autorisiert",
  },
  somethingWentWrong: "Etwas ist schiefgelaufen",
  backToHome: "Zurück zur Startseite",
  "404": "Diese Seite konnte nicht gefunden werden",
  passwordsDoNotMatch: "Passwörter stimmen nicht überein",
} satisfies EnErrorBase;
