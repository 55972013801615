import type { EnCountries } from "../en/countries";

export default {
  US: "Vereinigte Staaten",
  CA: "Kanada",
  GB: "Vereinigtes Königreich",
  DE: "Deutschland",
  FR: "Frankreich",
  JP: "Japan",
  AU: "Australien",
  CN: "China",
  IN: "Indien",
  BR: "Brasilien",
  MX: "Mexiko",
  ES: "Spanien",
  IT: "Italien",
  NL: "Niederlande",
  RU: "Russland",
  PL: "Polen",
  CZ: "Tschechische Republik",
  SK: "Slowakei",
} satisfies EnCountries;
