import type { SupportBase } from "../en/support";

export default {
  title: "Soporte",
  subtitle: "Obtenga datos consistentes y reproducibles con nuestra gama completa de proteínas.",
  description:
    "En Genotic, nos comprometemos a proporcionar el soporte y los recursos que necesita para aprovechar al máximo nuestros productos. Nuestro equipo de soporte está aquí para garantizar que tenga acceso a las herramientas, la información y la orientación necesarias para lograr resultados exitosos.",
  sidebar: {
    technicalSupport: "Soporte Técnico",
    productDocumentation: "Documentación del Producto",
    orderingAndPaymentSupport: "Soporte de Pedidos y Pagos",
    shippingAndDelivery: "Envío y Entrega",
    returnsAndRefunds: "Devoluciones y Reembolsos",
    collaborationsAndCustomOrders: "Colaboraciones y Pedidos Personalizados",
    contactInformation: "Información de Contacto",
    FAQ: "Preguntas Frecuentes",
  },
  technicalSupport: {
    title: "Soporte Técnico",
    description:
      "Ofrecemos soporte técnico integral para todos nuestros productos. Nuestro equipo experto está listo para ayudarle con cualquier pregunta o solución de problemas que pueda tener. Para obtener ayuda con los detalles del producto, consulte nuestra Base de Conocimientos o comuníquese directamente con nuestro equipo de soporte técnico.",
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  productDocumentation: {
    title: "Documentación del Producto (Por confirmar)",
    description:
      "Ofrecemos soporte técnico integral para todos nuestros productos. Nuestro equipo experto está listo para ayudarle con cualquier pregunta o solución de problemas que pueda tener. Para obtener ayuda con los detalles del producto, consulte nuestra Base de Conocimientos o comuníquese directamente con nuestro equipo de soporte técnico.",
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Soporte de Pedidos y Pagos",
    description:
      "Nos esforzamos por hacer que el proceso de pedido sea sencillo y directo. Ya sea que esté comprando un solo artículo o realizando un pedido al por mayor, ofrecemos varias opciones para satisfacer sus necesidades.",
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Envío y Entrega",
    description:
      "Nuestros productos se envían a todo el mundo con un enfoque en la entrega rápida y confiable. Nos asociamos con proveedores de envío de confianza para garantizar que su pedido llegue en condiciones óptimas.",
    tableItems: {
      1: {
        key: "Opciones de Envío",
        value: "Por confirmar",
      },
      2: {
        key: "Seguimiento de Pedidos",
        value: "Rastree su envío en tiempo real una vez que se haya despachado.",
      },
      3: {
        key: "Envíos Internacionales",
        value: "Por confirmar",
      },
    },
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  returnsAndRefunds: {
    title: "Devoluciones y Reembolsos",
    description:
      "Nos esforzamos por proporcionar productos de alta calidad, pero si necesita devolver un artículo o solicitar un reembolso, estamos aquí para ayudarle",
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  collaborationsAndCustomOrders: {
    title: "Colaboraciones y Pedidos Personalizados",
    description:
      "Genotic busca activamente la colaboración con instituciones de investigación, empresas biotecnológicas y socios de la industria. Ofrecemos soluciones flexibles para aquellos que necesitan productos o servicios personalizados para satisfacer sus necesidades específicas de investigación.",
    tableItems: {
      1: {
        key: "Proyectos de Investigación Colaborativa",
        value:
          "Estamos ansiosos por explorar oportunidades de co-desarrollo con otros innovadores en el campo de las ciencias de la vida. Contáctenos para discutir posibles asociaciones.",
      },
      2: {
        key: "Solicitudes de Productos Personalizados",
        value:
          "Ofrecemos soluciones a medida, incluyendo proteínas y kits personalizados, para satisfacer las demandas únicas de su investigación. Comuníquese con nuestro equipo para obtener más información.",
      },
    },
  },
  contactInformation: {
    title: "Información de Contacto",
    description:
      "Contáctenos - Siempre estamos aquí para ayudar, ya sea que necesite asistencia con un pedido o tenga preguntas técnicas. Nuestro equipo de soporte está listo para ayudarle.",
    contact: {
      0: {
        title: "Consultas Generales",
        email: "general_inquiries@genotic.com",
      },
      1: {
        title: "Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  faq: {
    title: "Preguntas Frecuentes",
    technical: {
      title: "Técnicas",
      qAndA: {
        1: {
          question:
            "¿Cuáles son las condiciones de almacenamiento recomendadas para sus productos?",
          answer:
            "Todos los productos liofilizados deben almacenarse a temperatura ambiente. Para productos en forma líquida, se recomienda el almacenamiento a 4 °C o en un congelador. Por favor, consulte la hoja de producto detallada para obtener instrucciones de almacenamiento específicas para cada producto.",
        },
        2: {
          question:
            "¿Cómo puedo obtener los Certificados de Análisis (COA) o las Hojas de Datos de Seguridad (SDS) de sus productos?",
          answer:
            "Todos los documentos necesarios, incluyendo la Hoja de Datos de Seguridad, la Hoja de Datos y el Certificado de Análisis, están directamente disponibles en la página de cada producto. Estos documentos pueden ser accedidos y descargados para su conveniencia.",
        },
        3: {
          question:
            "¿Qué medidas de control de calidad se implementan para garantizar la consistencia del producto?",
          answer:
            "Implementamos rigurosas medidas de control de calidad para cada lote de productos. Esto incluye electroforesis en gel para evaluar la pureza y el peso molecular, Interferometría de Biocapa (BLI) para medir las interacciones proteicas, y análisis de Resonancia de Plasmón Superficial (SPR) para ciertas proteínas. Para las enzimas, realizamos ensayos de actividad enzimática específicos para cada enzima, como se detalla en la hoja de datos de seguridad del producto. Además, realizamos SDS-PAGE y, para algunas proteínas, análisis de Western Blot para garantizar la más alta calidad.",
        },
        4: {
          question: "¿Cómo puedo solucionar problemas si un producto no funciona como se esperaba?",
          answer:
            "Por favor, contacte a nuestro equipo de soporte técnico para obtener asistencia. Estamos aquí para ayudarle a resolver cualquier problema y garantizar el rendimiento óptimo de nuestros productos.",
        },
        5: {
          question: "¿Ofrecen formulaciones personalizadas o modificaciones de sus productos?",
          answer:
            'Sí, ofrecemos formulaciones personalizadas y modificaciones de nuestros productos. Toda la información relevante se puede encontrar en la sección "Servicios" de nuestro sitio web. Para información adicional, por favor contáctenos por correo electrónico a sales@genotic.com.',
        },
        6: {
          question:
            "¿Están sus productos validados para aplicaciones de investigación específicas (por ejemplo, inmunoensayos, diagnósticos)?",
          answer:
            "Actualmente estamos en proceso de validar nuestros productos para aplicaciones específicas. En un futuro próximo, proporcionaremos información detallada indicando qué productos son adecuados para aplicaciones como Western Blot e inmunohistoquímica. Por favor, manténgase atento a las actualizaciones.",
        },
        7: {
          question: "¿Cómo debo manejar los productos sensibles a la temperatura?",
          answer:
            "Por favor, siga las instrucciones específicas de almacenamiento proporcionadas en la hoja del producto. Generalmente, los productos líquidos deben almacenarse a 4 °C o en el congelador para mantener su estabilidad. El almacenamiento adecuado garantiza la eficacia y longevidad del producto.",
        },
        8: {
          question:
            "¿Puedo acceder a protocolos, guías de uso o documentación técnica para sus productos?",
          answer:
            "En este momento, no ofrecemos protocolos o guías de usuario, pero planeamos agregarlos en el futuro. Sin embargo, proporcionamos documentación técnica y una colección de datos agregados de artículos científicos, que se pueden encontrar en las páginas de productos relevantes. Estamos comprometidos a apoyar sus necesidades de investigación y actualizaremos continuamente nuestros recursos.",
        },
        9: {
          question: "¿Cuál es la vida útil de sus productos y cómo se determina?",
          answer:
            "La vida útil de nuestros productos depende del método de producción y las condiciones de almacenamiento. Para productos liofilizados, la vida útil es de aproximadamente 12 meses cuando se almacenan en un lugar fresco y oscuro. Para productos congelados, se proporcionará información específica sobre la vida útil. Como nuestras proteínas son de reciente desarrollo, estamos realizando estudios de estabilidad continuos para proporcionar datos precisos sobre la vida útil.",
        },
        10: {
          question: "¿Cuál es su proceso de consistencia entre lotes?",
          answer:
            "Aseguramos una excepcional consistencia entre lotes produciendo nuestros anticuerpos mediante la modificación genética de células y estableciendo líneas celulares estables, eliminando el uso de animales en la producción. También utilizamos medios químicamente definidos sin ningún sustrato derivado de animales, garantizando una alta reproducibilidad y calidad consistente en todos los lotes.",
        },
        11: {
          question:
            "¿Cómo aseguran la reproducibilidad de los resultados utilizando sus productos?",
          answer:
            "Al emplear métodos de producción consistentes y utilizar medios químicamente definidos, garantizamos una alta reproducibilidad de nuestros productos. Nuestros rigurosos procesos de control de calidad aseguran que pueda lograr resultados confiables y consistentes en su investigación.",
        },
        12: {
          question:
            "¿Qué debo hacer si necesito ayuda con la integración del producto en mi flujo de trabajo?",
          answer:
            "Por favor, contacte a nuestro equipo de soporte técnico para obtener asistencia personalizada. Nuestros expertos están listos para ayudarle a integrar nuestros productos en su flujo de trabajo y abordar cualquier pregunta o inquietud que pueda tener.",
        },
      },
    },
    nonTechnical: {
      title: "No Técnicas",
      qAndA: {
        1: {
          question: "¿Cuál es su política de devoluciones y reembolsos?",
          answer:
            "Ofrecemos una política de devolución de 30 días en todos nuestros productos. Si no está completamente satisfecho, ya sea que el producto no se haya utilizado o no haya funcionado como se esperaba, proporcionamos un reembolso completo sin hacer preguntas. Confiamos en la calidad de nuestros productos y los respaldamos completamente. Su satisfacción es nuestra máxima prioridad.",
        },
        2: {
          question: "¿Cuáles son sus opciones de envío y cuánto tarda la entrega?",
          answer:
            "Ofrecemos varias opciones de envío para satisfacer sus necesidades. Los tiempos de entrega dependen del producto específico y de su ubicación. Se proporcionan estimaciones de entrega exactas en la página de cada producto, con actualizaciones de stock en tiempo real para garantizar una disponibilidad precisa.",
        },
        3: {
          question: "¿Realizan envíos internacionales y hay alguna restricción?",
          answer:
            "Sí, realizamos envíos internacionales. Para hacer un pedido internacional, por favor contáctenos para obtener una cotización personalizada y discutir cualquier posible restricción basada en las regulaciones de importación de su país. Estamos dedicados a facilitar el acceso global a nuestros productos mientras cumplimos con todas las leyes y estándares aplicables.",
        },
        4: {
          question: "¿Cómo puedo rastrear mi pedido después de que ha sido enviado?",
          answer:
            "Una vez que su pedido ha sido despachado, le enviaremos un número de seguimiento por correo electrónico. Puede usar este número para monitorear el progreso de su envío a través del sistema de seguimiento del transportista. Si necesita ayuda para rastrear su pedido, no dude en contactarnos.",
        },
        5: {
          question: "¿Qué métodos de pago aceptan?",
          answerHTML:
            '{"time": 1727873139304, "blocks": [{"id": "Jrq99IXsgB", "data": {"text": "Aceptamos los siguientes métodos de pago:"}, "type": "paragraph"}, {"id": "-0L4jom3aw", "data": {"items": ["Transferencias Bancarias", "Pagos con Tarjeta de Crédito y Débito", "Métodos de Pago Locales Soportados por Stripe"], "style": "unordered"}, "type": "list"}], "version": "2.24.3"}',
        },
        6: {
          question: "¿Cómo puedo solicitar una cotización para pedidos a granel o personalizados?",
          answer:
            "Para pedidos a granel, por favor contáctenos por correo electrónico a sales@genotic.com ",
        },
        7: {
          question:
            "¿Ofrecen descuentos para instituciones académicas, organizaciones sin fines de lucro o compras a granel?",
          answer:
            "Sí, ofrecemos descuentos para pedidos a granel, instituciones académicas y organizaciones sin fines de lucro. Podemos proporcionar cupones que otorgan descuentos. Por favor, contáctenos para discutir cómo podemos acomodar sus necesidades.",
        },
        8: {
          question: "¿Puedo abrir una cuenta de crédito con su empresa?",
          answer:
            "Sí, ofrecemos cuentas de crédito a empresas con las que tenemos una cooperación establecida. Una vez que vemos que todo funciona bien, podemos acordar términos de pago posteriores al pedido. Por favor, contáctenos para discutir el proceso de solicitud.",
        },
        9: {
          question: "¿Cuál es su política de garantía para los productos?",
          answer:
            "Respaldamos la calidad y fiabilidad de nuestros productos. Cada producto viene con información detallada y documentación de investigación que describe las pruebas específicas y los controles de calidad realizados. Nuestros productos son consistentes entre lotes porque utilizamos métodos de producción libres de animales. Si encuentra algún problema, ofrecemos un reembolso completo o reemplazo. Nuestra política de garantía refleja nuestro compromiso de proporcionar productos confiables y de alta calidad que satisfagan sus necesidades de investigación.",
        },
        10: {
          question:
            "¿Están involucrados en alguna iniciativa de sostenibilidad o abastecimiento ético?",
          answer:
            "Aunque somos una empresa pequeña y aún no participamos oficialmente en iniciativas de sostenibilidad, estamos comprometidos con prácticas responsables. Estamos implementando soluciones de energía renovable, con el objetivo de operar fuera de la red. Además, no utilizamos animales en nuestro diseño de anticuerpos.",
        },
        11: {
          question: "¿Puedo programar una demostración de producto o una consulta?",
          answer:
            "Sí, estaríamos encantados de organizar una demostración de producto o una consulta con usted. Podemos programar videollamadas para discutir nuestros productos en detalle y abordar cualquier pregunta que pueda tener. Por favor, envíenos un correo electrónico a general_inquires@genotic.com para establecer un horario conveniente.",
        },
        12: {
          question: "¿Cómo actualizo la información de mi cuenta o gestiono mis pedidos en línea?",
          answer:
            "Puede actualizar la información de su cuenta y gestionar sus pedidos iniciando sesión en su cuenta en nuestro sitio web. Todo es accesible a través del panel de usuario, donde puede editar detalles personales, revisar su historial de pedidos y rastrear pedidos actuales.",
        },
        13: {
          question: "¿A quién contacto para soporte general al cliente o asistencia técnica?",
          answer:
            "Para soporte general al cliente o asistencia técnica, por favor consulte la información de contacto relevante a continuación.",
        },
      },
    },
  },
} satisfies SupportBase;
