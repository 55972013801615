import type { Saleor, SaleorErrors } from "../en/saleor";

const errors = {
  common: {
    UNIQUE: "Einzigartig",
    INVALID: "Ungültig",
    NOT_FOUND: "Nicht gefunden",
  },
  CheckoutError: {
    VOUCHER_NOT_APPLICABLE: "Gutschein nicht anwendbar",
    REQUIRED: "Erforderlich",
    NO_LINES: "Keine Linien",
    INVALID: {
      text: "Ungültig",
      phone: "Ungültige @.lower:address.phoneNumber",
      countryArea: "Ungültiges @.lower:address.countryArea",
      postalCode: "Ungültige @.lower:address.postalCode",
    },
    INVALID_SHIPPING_METHOD: "Ungültige Versandmethode",
    QUANTITY_GREATER_THAN_LIMIT: "Menge größer als Grenze",
    EMAIL_NOT_SET: "Email nicht gesetzt",
    NOT_FOUND: "Nicht gefunden",
    PRODUCT_NOT_PUBLISHED: "Produkt nicht veröffentlicht",
    PRODUCT_UNAVAILABLE_FOR_PURCHASE: "Produkt nicht verfügbar für Kauf",
    INSUFFICIENT_STOCK: "Unzureichender Lagerbestand",
    ZERO_QUANTITY: "Menge auf null",
    UNAVAILABLE_VARIANT_IN_CHANNEL: "Variante nicht verfügbar im Kanal",
  },
  AccountError: {
    INVALID_CREDENTIALS: "Ungültige Anmeldeinformationen",
    INVALID_PASSWORD: "Ungültiges Passwort",
    NOT_FOUND: "Nicht gefunden",
    PASSWORD_TOO_SHORT: "Passwort zu kurz",
    PASSWORD_TOO_COMMON: "Passwort zu häufig",
    PASSWORD_TOO_SIMILAR: "Passwort zu ähnlich",
    REQUIRED: "Erforderlich",
    UNIQUE: "Einzigartig",
    ACCOUNT_NOT_CONFIRMED: "Konto nicht bestätigt",
  },
  TransactionInitializeError: {
    CHECKOUT_COMPLETION_IN_PROGRESS: "Kaufabschluss läuft",
  },
  TransactionProcessError: {
    NOT_FOUND: "Transaktion nicht gefunden",
    TRANSACTION_ALREADY_PROCESSED: "Transaktion bereits verarbeitet",
    INVALID: "Ungültig",
    CHECKOUT_COMPLETION_IN_PROGRESS: "Kaufabschluss läuft",
  },
} satisfies SaleorErrors;

export default {
  errors,
  order: {
    Status: {
      CANCELED: "Abgebrochen",
      DRAFT: "Entwurf",
      EXPIRED: "Ablaufen",
      FULFILLED: "Erledigt",
      PARTIALLY_FULFILLED: "Teilweise erledigt",
      PARTIALLY_RETURNED: "Teilweise zurückgegeben",
      RETURNED: "Zurückgegeben",
      UNCONFIRMED: "Nicht bestätigt",
      UNFULFILLED: "Nicht erfüllt",
    },
    ChargeStatus: {
      FULL: "Voll",
      NONE: "Keine",
      OVERCHARGED: "Übergeladen",
      PARTIAL: "Teilweise",
    },
  },
  payment: {
    ChargeStatus: {
      CANCELLED: "Abgebrochen",
      FULLY_CHARGED: "Voll",
      FULLY_REFUNDED: "Voll zurückerstattet",
      NOT_CHARGED: "Nicht bezahlt",
      PARTIALLY_CHARGED: "Teilweise bezahlt",
      PARTIALLY_REFUNDED: "Teilweise zurückerstattet",
      PENDING: "Ausstehend",
      REFUSED: "Abgelehnt",
    },
  },
} satisfies Saleor;
