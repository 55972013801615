import type { ZodI18n } from "../en/zod";

export default {
  errors: {
    too_small: {
      string: {
        inclusive: "Die Zeichenkette muss mindestens {minimum} Zeichen enthalten",
      },
    },
    invalid_type_received_undefined: "Ein Wert wurde erwartet, aber undefined wurde empfangen",
    required_field: "Dieses Feld ist erforderlich",
    invalid_type: {
      string: {
        expected: "Eine Zeichenkette wurde erwartet, aber {received} wurde empfangen",
      },
      number: {
        expected: "Eine Zahl wurde erwartet, aber {received} wurde empfangen",
      },
    },
    too_big: {
      string: {
        inclusive: "Die Zeichenkette darf höchstens {maximum} Zeichen enthalten",
      },
    },
    invalid_string: {
      email: "Ungültige E-Mail-Adresse",
      url: "Ungültige URL",
    },
    custom: "Ungültige Eingabe",
  },
  validations: {
    email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    phone: "Bitte geben Sie eine gültige Telefonnummer ein",
  },
} satisfies ZodI18n;
