import type { ZodI18n } from "../en/zod";

export default {
  errors: {
    too_small: {
      string: {
        inclusive: "La cadena debe contener al menos {minimum} carácter(es)",
      },
    },
    invalid_type_received_undefined: "Se esperaba un valor, pero se recibió undefined",
    required_field: "Este campo es obligatorio",
    invalid_type: {
      string: {
        expected: "Se esperaba una cadena, pero se recibió {received}",
      },
      number: {
        expected: "Se esperaba un número, pero se recibió {received}",
      },
    },
    too_big: {
      string: {
        inclusive: "La cadena debe contener como máximo {maximum} carácter(es)",
      },
    },
    invalid_string: {
      email: "Dirección de correo electrónico inválida",
      url: "URL inválida",
    },
    custom: "Entrada inválida",
  },
  validations: {
    email: "Por favor, introduzca una dirección de correo electrónico válida",
    phone: "Por favor, introduzca un número de teléfono válido",
  },
} satisfies ZodI18n;
